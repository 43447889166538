import React from 'react';
import { Typography, AppBar, Toolbar, useTheme } from '@mui/material';
import { snackbarContext } from 'src/wrappers/SnackBarWrapper';
import topAppBarStyles from '../../creative_commons/topBarContainer/topAppBar.styles';
import { storage } from '../../api/base';
import { SPOOFING_ACTION } from '../../constants/data/topAppBar.constants';
import { spoofuser } from '../../api/spoof';
import { DefaultButton } from '../StyledComponent';

const TopBar = () => {
  const theme = useTheme();
  const styles = topAppBarStyles(theme);
  const { setSnackBar } = snackbarContext();
  const sfooningUser = storage.getKey('spoofingUserDetails');

  const removeSpoofLoginUser = async () => {
    const token = await storage.getKey('userToken');
    const data = {
      email: sfooningUser?.spoofingEmail,
      action: SPOOFING_ACTION.logout,
      token,
    };
    try {
      const response = await spoofuser(data);
      setSnackBar({ message: response?.data?.action, success: true, open: true });
      setTimeout(() => {
        storage.removeKey('spoofingUserDetails');
        storage.removeKey('userToken');
        window.location.reload();
      }, 500);
    } catch (ex: any) {
      setSnackBar({ message: ex?.response?.data?.message, success: false, open: true });
    }
  };

  return sfooningUser?.isSpoofing ? (
    <AppBar position="static" sx={styles.spoofTopBar}>
      <Toolbar>
        <Typography component="div">
          You logged in as &apos;{sfooningUser?.spoofingEmail}&apos;. &nbsp;
          <DefaultButton onClick={removeSpoofLoginUser}>Click here</DefaultButton>&nbsp; to return to your user.
        </Typography>
      </Toolbar>
    </AppBar>
  ) : null;
};

export default TopBar;
