import { useCallback, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import type { RootState, AppDispatch } from './index';
import { storage } from '../api/base';

type InitialProps = {
  initialData: any;
  INITIAL_DATA: any;
};

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useTokenCallBack = () => {
  const sfooningUser = storage.getKey('spoofingUserDetails');
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  return useCallback(
    (callback: any) => {
      if (isAuthenticated && isFunction(callback)) {
        getAccessTokenSilently().then((token) => {
          callback(sfooningUser?.isSpoofing ? (token = sfooningUser?.token) : token);
        });
      }
    },
    [isAuthenticated, getAccessTokenSilently]
  );
};

export const useTokenCallbackPromise = () => {
  const makeTokenCall = useTokenCallBack();
  return useCallback(
    () =>
      new Promise<string>((resolve) => {
        makeTokenCall((token: string) => {
          resolve(token);
        });
      }),
    []
  );
};

export const useInitialData = ({ INITIAL_DATA, initialData }: InitialProps) =>
  useMemo(() => {
    const isDataEmpty = isEmpty(initialData);
    const initialValues = isDataEmpty ? INITIAL_DATA : initialData;
    return {
      isDataEmpty,
      initialValues,
      isEdit: !isDataEmpty,
    };
  }, [INITIAL_DATA, initialData]);
