import baseAPIFactory, { handleTokenHeaders, TokenParams } from './base';

export interface UserCreateInitialType {
  full_name: string;
  title: string | null;
  email: string | null;
  phone: string | null;
  cell_number: string | null;
  account: number;
  profile_image: any | null;
  user_status: string | null;
  user_role: number | null;
  vivacity_news: boolean | null;
  product_updates: boolean | null;
  vivacity_weekly: boolean | null;
  in_house_repair: boolean | null;
  user_notifications: boolean | null;
  beta_tester: boolean | null;
  token?: string;
}

export interface userDetails {
  record_id?: number | null;
  full_name: string;
  title: string | null;
  email: string | null;
  phone: string | null;
  cell_number: string | null;
  account: number;
  profile_image: any | null;
  user_status: string | null;
  user_role: number | null;
  vivacity_news: boolean | null;
  product_updates: boolean | null;
  vivacity_weekly: boolean | null;
  in_house_repair: boolean | null;
  user_notifications: boolean | null;
  beta_tester: boolean | null;
  building?: string | null;
  building_record_id?: number;
  role_record_id?: number;
}

export interface PrismaExternalUserCreateOneInput {
  title: string;
  full_name: string;
  email: string;
  phone: string;
  cell_number?: string;
  external_user_role_id: number;
  building_id?: number;
  account_number: number;
}

export interface userBulk {
  record_id: number;
  full_name: string;
  title: string | null;
  email: string | null;
  phone: string | null;
  cell_number: string | null;
  account: number;
  profile_image: any | null;
  user_status: string | null;
  user_role: number | null;
  vivacity_news: boolean | null;
  product_updates: boolean | null;
  vivacity_weekly: boolean | null;
  in_house_repair: boolean | null;
  user_notifications: boolean | null;
  beta_tester: boolean | null;
  role_description: string | null;
  role_name: string | null;
  totalAccountUserPages?: any;
}

export interface userPaid {
  record_id: number;
  user_id: number;
  account_id: number;
  created_at: string;
  full_name: string;
  account_name: string;
  fulfillment_date: string;
  request_type: string;
  message: string;
}

export interface AdminApprove {
  record_id: number;
  request_type: string;
  user_id: number;
  account_id: number;
  token?: string;
}

export interface AdminDeny {
  record_id: string | number;
  token?: string;
}

const END_POINTS = {
  FETCH_ONE: () => '/user/email/',
  FETCH_BULK: () => '/user/get_users/email/',
  FETCH_BULK_ADMIN: () => '/user/get_users/bulk_user/admin/',
  GET_ALL_USERS: () => '/user',
  FETCH_USER: (recordId: string | number) => `/user/${recordId}`,
  GET_PAID_USERS: () => '/vivacity-admin/getAllPendingRequests',
  APPROVE_USERS: (recordId: string | number) => `/vivacity-admin/updateAccountAndRequest/${recordId}`,
  POST: () => '/user/',
  DELETE_EXTERNAL_USER: () => '/external_user/',
  DENY_REQ: (recordId?: string | number) => `/vivacity-admin/deleteRequest/${recordId}`,
  UPDATE_USER: (userId: number | undefined) => `/external_user/${userId}`,
  CREATE_EXTERNAL_USER: () => '/external_user/',
  GET_ADMIN_USERS: () => '/user/getAllAdmin',
};

export const getOneUser = async ({ userEmail, token }: { userEmail?: string | any; token?: string }) =>
  baseAPIFactory.get<userDetails[]>(`${END_POINTS.FETCH_ONE()}${userEmail}`, handleTokenHeaders({ token }));

export const fetchOneUser = async ({ recordId, token }: { recordId: string | number; token?: string }) =>
  baseAPIFactory.get<userDetails>(END_POINTS.FETCH_USER(recordId), handleTokenHeaders({ token }));

export const updateOneUser = async (userId: number, userData: any, props: TokenParams) => {
  const response = await baseAPIFactory.put(END_POINTS.UPDATE_USER(userId), userData, {
    ...handleTokenHeaders(props),
  });
  return response;
};

export const getBulkUserAdmin = async ({
  offset,
  filterName,
  token,
}: {
  offset: number;
  filterName?;
  token?: string;
}) =>
  baseAPIFactory.get<any>(`${END_POINTS.FETCH_BULK_ADMIN()}${offset}/${filterName}`, {
    ...handleTokenHeaders({ token }),
  });

export const getBulkUser = async ({
  userEmail,
  token,
  extraParams = null,
}: {
  userEmail?: string;
  token?: string;
  extraParams?: any;
}) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<userBulk[]>(`${END_POINTS.FETCH_BULK()}${userEmail}`, {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const createExternalUser = async (userData: PrismaExternalUserCreateOneInput, props: TokenParams) => {
  const response = await baseAPIFactory.post(END_POINTS.CREATE_EXTERNAL_USER(), userData, {
    ...handleTokenHeaders(props),
  });
  return response;
};

export const createNewUser = async (data: UserCreateInitialType) =>
  baseAPIFactory.post(END_POINTS.POST(), data, handleTokenHeaders(data));

export const getPaidUsers = async ({ token }: { token: string }) =>
  baseAPIFactory.get<userPaid[]>(END_POINTS.GET_PAID_USERS(), handleTokenHeaders({ token }));

export const getAllUsers = async ({ token, page }: { token: string; page?: number }) => {
  const params: { [key: string]: any } = {};
  if (page) {
    params.page_no = page;
  }
  return baseAPIFactory.get<userDetails[]>(END_POINTS.POST(), {
    params,
    ...handleTokenHeaders({ token }),
  });
};

export const getAllUsersAdmin = async ({ token, page }: { token: string; page?: number }) => {
  const params: { [key: string]: any } = {};
  if (page) {
    params.page_no = page;
  }
  return baseAPIFactory.get<userDetails[]>(END_POINTS.FETCH_BULK_ADMIN(), {
    params,
    ...handleTokenHeaders({ token }),
  });
};

export const createFaciliatorUser = async (data: FormData, token?: any) =>
  baseAPIFactory.post(END_POINTS.POST(), data, handleTokenHeaders(token, { 'Content-Type': 'multipart/form-data' }));

export const updateAdminApprove = async (data: AdminApprove) =>
  baseAPIFactory.put(END_POINTS.APPROVE_USERS(data?.record_id), data, handleTokenHeaders(data));

export const denyOne = async (data: AdminDeny) =>
  baseAPIFactory.put(END_POINTS.DENY_REQ(data?.record_id), data, handleTokenHeaders(data));

export const deleteExternalUser = async ({ recordId, token }: { recordId: number; token: string }) =>
  baseAPIFactory.delete(`${END_POINTS.DELETE_EXTERNAL_USER()}${recordId}`, handleTokenHeaders({ token }));

export const getAdminUsers = async ({ token }: { token: string }) =>
  baseAPIFactory.get(END_POINTS.GET_ADMIN_USERS(), handleTokenHeaders({ token }));
