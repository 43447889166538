import baseAPIFactory, { handleTokenHeaders } from './base';
import type { PaginatedResponse, TokenParams } from './base';

export type FetchParamTypes = {
  perPage?: number;
  pageNo?: number;
  status: string;
} & TokenParams;

export type RoadMapCreateInitialType = {
  title: string;
  description: string;
  status: string;
  created_by: number;
} & TokenParams;

export type NewVote = {
  user_id: string | number | undefined;
  roadmap_id: number;
  token?: string;
};

export type UpdateVote = {
  recordId: number;
} & TokenParams;

export type FetchParamTypesWithPage = {
  token: string;
  page?: number;
  search_field?: string;
  search_value?: number | string;
  sort?: string;
};

const END_POINTS = {
  FETCH_ALL: () => `/roadmap/`,
  FEATURE_ALL: () => `/roadmap/all`,
  GET_ITEM: (itemId: string | number | null) => `/roadmap/${itemId}`,
  CREATE_ROADMAP: () => `/roadmap/`,
  UPDATE_VOTE: (recordId: number) => `/roadmap/${recordId}/vote`,
  APPROVE_VOTE: (recordId: number) => `/roadmap/${recordId}/approve`,
  DENY_VOTE: (recordId: number) => `/roadmap/${recordId}`,
  GET_VOTES: (email: string | undefined) => `/user_vote/getVote/${email}`,
  UPLOAD_VOTES: () => `/user_vote`,
};

export type RoadMapItem = {
  record_id: number;
  title: string;
  description: string;
  status: string;
  votes: number;
  created_at: string;
  created_by: string;
  updated_at: string;
  canVote: boolean;
  hasVoted: boolean;
  percentage_complete: number;
  planned_quarter: number;
  planned_year: number;
};

export const getPaginatedList = async ({
  type,
  searchField,
  searchValue,
  contains,
  token,
}: {
  type: any;
  searchField?: string | number;
  searchValue?: string | number;
  contains?: string;
  token?: string;
}) => {
  const params: { [key: string]: any } = {};
  if (type) {
    params.type = type;
    if (searchField && searchValue) {
      params.search_field = searchField;
      params.search_value = searchValue;
    }
    if (contains) {
      params.contains = contains;
    }
  }
  return baseAPIFactory.get<PaginatedResponse<RoadMapItem>>(END_POINTS.FETCH_ALL(), {
    params,
    ...handleTokenHeaders({ token }),
  });
};

export const getFeatureList = async (props: FetchParamTypesWithPage) => {
  const params: { [key: string]: any } = {};
  if (props.page) {
    params.page_no = props.page;
  }
  if (props.search_field && props.search_value) {
    params.search_field = props.search_field;
    params.search_value = props.search_value;
  }
  return baseAPIFactory.get<RoadMapItem[]>(END_POINTS.FEATURE_ALL(), {
    params,
    ...handleTokenHeaders(props),
  });
};

export const getUserVotes = ({ userEmail, token }: { userEmail?: string | any; token?: string }) =>
  baseAPIFactory.get<any[]>(END_POINTS.GET_VOTES(userEmail), handleTokenHeaders({ token }));

export const createVote = async (data: NewVote) =>
  baseAPIFactory.post(END_POINTS.UPLOAD_VOTES(), data, handleTokenHeaders(data));

export const createRoadMap = async (data: RoadMapCreateInitialType) =>
  baseAPIFactory.post(
    END_POINTS.CREATE_ROADMAP(),
    {
      title: data.title,
      description: data.description,
      status: data.status,
      created_by: data.created_by,
    },
    handleTokenHeaders(data)
  );

export const updateVoteApi = async (data: UpdateVote) =>
  baseAPIFactory.post(END_POINTS.UPDATE_VOTE(data.recordId), {}, handleTokenHeaders(data));

export const fetchRoadMapById = async ({ itemId, token }: { itemId: string | number | null; token?: string }) =>
  baseAPIFactory.get<RoadMapItem>(END_POINTS.GET_ITEM(itemId), handleTokenHeaders({ token }));

export const approveRoadmap = async (data: UpdateVote) =>
  baseAPIFactory.put(END_POINTS.APPROVE_VOTE(data.recordId), {}, handleTokenHeaders(data));

export const denyRoadmap = async ({ token, recordId }: { token: string; recordId: number }) =>
  baseAPIFactory.delete(END_POINTS.DENY_VOTE(recordId), handleTokenHeaders({ token }));
