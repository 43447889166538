import { styled, Card, Typography, Button, TextField } from '@mui/material';
import Colors from '../constants/colors/colors.constant';

export const StyledCardTitle = styled(Typography)(({ theme }: any) => ({
  fontSize: theme.spacing(2.75),
  fontWeight: 'bold',
  lineHeight: theme.spacing(4.75),
  letterSpacing: 0,
  textAlign: 'left',
}));

export const StyledCard = styled(Card)(({ theme }: any) => ({
  borderRadius: theme.spacing(1.25),
}));

export const AdvancedButton = styled(Button)(({ theme }) => ({
  padding: '0.5em 0.75em',
  color: theme.palette.getContrastText(theme.palette.secondary.main),
}));

export const DefaultButton = styled(Button)(({ theme }) => ({
  color: Colors.primary.black,
  backgroundColor: Colors.primary.white,
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: theme.spacing(1),
  height: theme.spacing(5),
  textTransform: 'none',
  boxShadow: theme.shadows[2],
  '&:hover': {
    backgroundColor: Colors.primary.white,
    color: Colors.primary.main,
    boxShadow: theme.shadows[4],
  },
  '&.Mui-disabled': {
    backgroundColor: 'grey',
    color: 'lightgrey',
  },
}));

export const ExportButton = styled(Button)(({ theme }) => ({
  color: Colors.primary.black,
  backgroundColor: Colors.primary.white,
  border: `1px solid ${Colors.primary.main}`,
  borderRadius: theme.spacing(1),
  height: theme.spacing(3.5),
  textTransform: 'none',
  fontSize: '12px', // Smaller font size
  padding: '5px 10px', // Adjust padding to bring borders closer to the text
  '&.MuiButton-root:hover': {
    backgroundColor: Colors.primary.white,
    color: Colors.primary.main,
  },
  '&&:hover': {
    backgroundColor: Colors.primary.white,
    color: Colors.primary.main,
  },
  ':hover': {
    backgroundColor: Colors.primary.white,
    color: Colors.primary.main,
  },
}));

export const StyledTextField = styled(TextField)(({ theme }: any) => ({
  height: theme.spacing(5),
}));

export const buildingText = {
  margin: 0,
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '40px',
  letterSpacing: '0.00938em',
  color: '#000',
  textAlign: 'left',
  wordWrap: 'break-word',
};
