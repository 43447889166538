import isEmpty from 'lodash/isEmpty';
import baseAPIFactory, { handleTokenHeaders } from '../base';
import type { PagedResponse, TokenParams } from '../base';
import { TransformedQueryResult } from '../fleet_device';

export type DeviceInfo = {
  record_id: number;
  account?: number;
  serial_number: string;
  building?: string | number;
  import_number: null | string;
  device: any;
  device_sku?: string;
  base_warranty_sku?: string;
  base_warranty: null | number;
  base_warranty_id?: null | number;
  base_warranty_start_date?: Date | string;
  base_warranty_end_date?: Date | string;
  base_warranty_description?: string;
  advanced_warranty: null | number;
  advanced_warranty_start_date?: Date | string;
  advanced_warranty_end_date?: Date | string;
  advanced_warranty_description?: string;
  asset_tag: string;
  student: any;
  loaner_status: string;
  created_date: null | string;
  advanced_warranty_sku: null | string;
  google_id?: string;
  device_status?: string;
  is_verified?: boolean;
};

export type CreateDeviceData = {
  serial_number: string;
  building: number | null;
  import_number: null | string | number;
  device: number;
  base_warranty: number | null;
  base_warranty_start_date: string | null;
  base_warranty_end_date: string | null;
  advanced_warranty: number | null;
  advanced_warranty_start_date: string | null;
  advanced_warranty_end_date: string | null;
  asset_tag: string;
  student: number | null;
  loaner_status: string;
  created_date: Date | null;
  last_modified_by: number | null;
  created_by: number | null;
  account: string | number;
  device_status: string;
};

export type UpdateDeviceData = {
  account: number;
  serial_number: string;
  building: number;
  import_number: null | string;
  device: number;
  base_warranty: number;
  base_warranty_start_date: string;
  base_warranty_end_date: string;
  advanced_warranty: number;
  advanced_warranty_start_date: string;
  advanced_warranty_end_date: string;
  asset_tag: string;
  student: number | null;
  loaner_status: string;
  created_date: number;
  last_modified_by: number;
  created_by: number;
  token?: string;
  additional_info: any;
  deviceId?: any;
  isGAC?: any;
};

export type ImportData = {
  record_id: number;
  date_imported: string;
  importer: number;
  quantity_devices: number;
  import: {
    type: string;
    data: number;
  };
};

export type ImportUpdate = {
  record_id: number | null;
  date_imported: string | null;
  importer?: number | null;
  quantity_devices: number | null;
  import: {
    type: string | null;
    data: number | null;
  };
  token?: string;
};

export type ImportNew = {
  date_imported: string | null;
  importer?: number | null;
  quantity_devices: number | null;
  import: {
    type: string | null;
    data: number | null;
  };
  token?: string;
};

export type Assets = {
  record_id: number;
  title: string;
  description: string;
  image: string;
  acquire_date: string | Date;
  acquire_location: string;
  tag: string;
  notes: string;
  current_location: string;
  type: string;
};

export type UploadAsset = {
  title: string;
  description: string;
  profile_full_name: string;
  image: string | object;
  acquire_date: string | Date;
  acquire_location: string | Location;
  tag: number;
  notes: string;
  current_location: string;
  type: string;
  token?: string;
};

export type FetchParamTypesWithPage = {
  page?: number;
  search_field?: string;
  search_value?: number | string;
  sort?: string;
  contains?: string;
  per_page?: number;
};

export type deviceCategory = {
  category_name: string;
  category_description: string;
};

export type NotificationImports = {
  record_id: number;
  date_imported: string;
  importer: number;
  quantity_devices: number;
  import_file: string;
  account: number;
  status: string;
  user_data: string;
};

const END_POINTS = {
  FETCH_ALL_BY_BUILDING: (accountId: any) => `/fleet_device/all/${accountId}`,
  FETCH_ALL_AGGREGATED: () => `/fleet_device/aggregate-all`,
  FETCH_ALL_BY_DEVICEDETAILS: (accountId: any) => `/fleet_device/allDeviceDetails/${accountId}`,
  FETCH_ALL_BY_IMPORT: (importId: string | number) => `/fleet_device/import/${importId}`,
  FETCH_ALL_BY_ACCOUNT: (accountId: string | number) => `/fleet_device/${accountId}`,
  FETCH_ALL_BY_ACCOUNT_STATUS: (accountId: string | number, status?: string) =>
    `/fleet_device/account/${accountId}/${status}`,
  FETCH_ALL_WITH_ACCOUNT: (accountId: string | number) => `/fleet_device/all/${accountId}`,
  FETCH_ONE: (serialId: string | number) => `/device/${serialId}`,
  FETCH_REPAIR_TICKETS_BY_SERIAL_NUMBER: (accountId: string | number, serialNumber: string) =>
    `/repair_ticket/fleet_device/${accountId}/${serialNumber}`,
  FETCH_LIST_WITH_ACCOUNT: (accountId: string | number) => `/fleet_device/list/${accountId}`,
  GET_ONE: (serialId: string | number) => `/fleet_device/${serialId}`,
  CREATE_ONE: () => `/fleet_device`,
  UPDATE_ONE: (serialId: string | number) => `/fleet_device/${serialId}`,
  UPDATE_DEVICE_STATUS: (deviceId: string | number) => `/fleet_device/aggregate-update/${deviceId}`,
  DELETE_ONE: (serialId: string | number) => `/fleet_device/${serialId}`,
  CHECKOUT_ONE: (serialId: string | number, studentId: string | number | null) =>
    `/fleet_device/checkout/${serialId}/${studentId}`,
  CHECKIN_ONE: (serialId: string | number) => `/fleet_device/checkin/${serialId}`,
  updateAccountAndRequest: (recordId: string | number | null) => `/vivacity-admin/updateAccountAndRequest/${recordId}`,
  FETCH_ALL_Device: () => `/device/findByDevice`,
  GET_ASSETS_BY_ACCOUNT: (account: any) => `/account_asset/getByAccount/${account}`,
  UPLOADED_ASSET: () => '/account_asset',
  DELETE_ASSET: (recordId?: string | number) => `/account_asset/${recordId}`,
  UPDATE_ASSET: (recordId: string | number) => `/account_asset/${recordId}`,
  ALL_DEVICES: () => '/device/all',
  deciceCategory: () => '/eligible_devices/all',
  FETCH_ALL_IMPORTS: () => `/notification/all`,
  UPDATE_IMPORTS: (recordId: string | number) => `/notification/update_status/${recordId}`,
  FETCH_ALL_LONER_ACCOUNT_STATUS: (accountId: string | number, status?: string, loanerStatus?: string) =>
    `/fleet_device/account/${accountId}/${status}/${loanerStatus}`,
  FETCH_ALL_DEVICES_LONER_ACCOUNT: (accountId: string | number, status?: string, loanerStatus?: string) =>
    `/fleet_device/account/${accountId}/${status}/${loanerStatus}/getAllDevices`,
  getAssetsByAccount: (accountId: string | number) => `account_asset/${accountId}/getByAllAccount`,
  DEVICE_GET: (key: number | string) => `/app/device/${key}`,
  DEVICE_LIST: () => `/app/device`,
};

export const getAll = async (props: TokenParams) => {
  const params: { [key: string]: any } = {};
  if (props.page) {
    params.page_no = props.page;
  }
  return baseAPIFactory.get<DeviceInfo[]>(END_POINTS.FETCH_ALL_BY_BUILDING(props.accountId), {
    params,
    ...handleTokenHeaders(props),
  });
};
export const getAllDeviceDetails = async (props: TokenParams) => {
  const params: { [key: string]: any } = {};
  return baseAPIFactory.get<DeviceInfo[]>(END_POINTS.FETCH_ALL_BY_DEVICEDETAILS(props.accountId), {
    params,
    ...handleTokenHeaders(props),
  });
};
export const getAllRepairTickets = async ({
  accountId,
  serialNumber,
  token,
}: {
  accountId: string | number;
  serialNumber: string;
  token: string;
}) =>
  baseAPIFactory.get<any>(
    END_POINTS.FETCH_REPAIR_TICKETS_BY_SERIAL_NUMBER(accountId, serialNumber),
    handleTokenHeaders({ token })
  );
export const getAllByImportId = async ({ importId, token }: { importId?: string | any; token?: string }) =>
  baseAPIFactory.get<DeviceInfo[]>(END_POINTS.FETCH_ALL_BY_IMPORT(importId), {
    ...handleTokenHeaders(token),
  });
export const getAllByAccountIdNoPage = async ({
  accountId,
  token,
  params,
}: {
  accountId: string | number;
  token?: string;
  params?: any;
}) => {
  const headers = handleTokenHeaders({ token });
  return baseAPIFactory.get<any>(END_POINTS.FETCH_ALL_WITH_ACCOUNT(accountId), {
    ...headers,
    ...(!isEmpty(params) ? { params } : {}),
  });
};

export const getAllByListAccountIdNoPage = async ({
  accountId,
  token,
  params,
}: {
  accountId: string | number;
  token?: string;
  params?: any;
}) => {
  const headers = handleTokenHeaders({ token });
  return baseAPIFactory.get<any>(END_POINTS.FETCH_LIST_WITH_ACCOUNT(accountId), {
    ...headers,
    ...(!isEmpty(params) ? { params } : {}),
  });
};

export const getAllDevices = async (token: string) =>
  baseAPIFactory.get<any[]>(END_POINTS.ALL_DEVICES(), handleTokenHeaders({ token }));

export const getAllByAccountId = async (
  accountId: string | number,
  token: string,
  status: string,
  props: FetchParamTypesWithPage
) => {
  const params: { [key: string]: any } = {};
  if (props.page) {
    params.page_no = props.page;
  }
  if (props.contains) {
    params.contains = props.contains;
  }
  if (props.search_field && props.search_value) {
    params.search_field = props.search_field;
    params.search_value = props.search_value;
  }
  const newStatus = status === 'Active' ? 'Active' : 'Retired';
  return baseAPIFactory.get<DeviceInfo[]>(END_POINTS.FETCH_ALL_BY_ACCOUNT_STATUS(accountId, newStatus), {
    params,
    ...handleTokenHeaders({ props, token }),
  });
};

export const exportAllByAccountId = async (
  accountId: string | number,
  token: string,
  status: string,
  props: FetchParamTypesWithPage
) => {
  const params: { [key: string]: any } = {};
  if (props.contains) {
    params.contains = props.contains;
  }
  if (props.per_page) {
    params.per_page = props.per_page;
  }
  if (props.search_field && props.search_value) {
    params.search_field = props.search_field;
    params.search_value = props.search_value;
  }
  const newStatus = status === 'Active' ? 'Active' : 'Retired';
  return baseAPIFactory.get<DeviceInfo[]>(END_POINTS.FETCH_ALL_BY_ACCOUNT_STATUS(accountId, newStatus), {
    params,
    ...handleTokenHeaders({ props, token }),
  });
};

export const fetchOne = (serialId: string | number, props: TokenParams) =>
  baseAPIFactory.get(END_POINTS.FETCH_ONE(serialId), {
    ...handleTokenHeaders(props),
  });
export const getOne = (serialId: string | number, props: TokenParams) =>
  baseAPIFactory.get(END_POINTS.GET_ONE(serialId), {
    ...handleTokenHeaders(props),
  });
export const createOne = async (deviceData: CreateDeviceData, props: TokenParams) =>
  baseAPIFactory.post<DeviceInfo>(END_POINTS.CREATE_ONE(), deviceData, {
    ...handleTokenHeaders(props),
  });
export const updateOne = async (serialId: string | number, deviceData: Partial<CreateDeviceData>, props: TokenParams) =>
  baseAPIFactory.put(END_POINTS.UPDATE_ONE(serialId), deviceData, {
    ...handleTokenHeaders(props),
  });
export const aggregateUpdateOne = async (data: UpdateDeviceData) => {
  const { token, isGAC, ...otherData } = data;
  return baseAPIFactory.put(
    END_POINTS.UPDATE_DEVICE_STATUS(data.deviceId),
    {
      ...otherData,
      is_gac: isGAC,
    },
    handleTokenHeaders({ token })
  );
};
export const deleteOne = async (serialId: string | number, props: TokenParams) =>
  baseAPIFactory.delete(END_POINTS.DELETE_ONE(serialId), {
    ...handleTokenHeaders(props),
  });
export const checkoutOne = async (data: UpdateDeviceData) =>
  baseAPIFactory.put(END_POINTS.CHECKOUT_ONE(data?.serial_number, data?.student), data, handleTokenHeaders(data));
export const checkinOne = async (data: UpdateDeviceData) =>
  baseAPIFactory.put(END_POINTS.CHECKIN_ONE(data?.serial_number), data, handleTokenHeaders(data));

export const updatedevice = async (data: UpdateDeviceData) =>
  baseAPIFactory.put(END_POINTS.UPDATE_ONE(data?.serial_number), data, handleTokenHeaders(data));

export const addDevicePaid = async (data: any) =>
  baseAPIFactory.put(END_POINTS.updateAccountAndRequest(data.record_id), data.message, handleTokenHeaders(data));

export const allDeviceSearch = async (data: any) => {
  const params: { [key: string]: any } = {};
  if (data.search_field) {
    params.search_field = data.search_field;
    params.search_value = data.search_value;
  }
  return baseAPIFactory.get<any>(END_POINTS.FETCH_ALL_Device(), {
    params,
    ...handleTokenHeaders(data),
  });
};

export const allImportsseach = async (data: any) => {
  const params: { [key: string]: any } = {};
  if (data.search_field) {
    params.search_field = data.search_field;
    params.search_value = data.search_value;
  }
  return baseAPIFactory.get<any>(END_POINTS.FETCH_ALL_IMPORTS(), {
    params,
    ...handleTokenHeaders(data),
  });
};
export const getAssetByAccount = async ({
  account,
  pageNo,
  searchField,
  searchValue,
  contains,
  token,
}: {
  account: any;
  pageNo?: string | number;
  searchField?: string | number;
  searchValue?: string | number;
  contains?: string;
  token?: string;
}) => {
  const params: { [key: string]: any } = {};

  if (searchField && searchValue) {
    params.search_field = searchField;
    params.search_value = searchValue;
  }
  if (pageNo) {
    params.page_no = pageNo;
  }
  if (contains) {
    params.contains = contains;
  }
  return baseAPIFactory.get<Assets[]>(END_POINTS.GET_ASSETS_BY_ACCOUNT(account), {
    params,
    ...handleTokenHeaders({ token }),
  });
};

export const updateAsset = async (data: any, recordId: number | string, token: string) =>
  baseAPIFactory.put(END_POINTS.UPDATE_ASSET(recordId), data, handleTokenHeaders({ token }));

export const createAsset = async (data: any, token: string) =>
  baseAPIFactory.post(END_POINTS.UPLOADED_ASSET(), data, handleTokenHeaders({ token }));

export const deleteAsset = async ({ token, recordId }: { token: string; recordId?: string | number }) =>
  baseAPIFactory.delete(END_POINTS.DELETE_ASSET(recordId), handleTokenHeaders({ token }));

export const listCategory = async ({ token }: { token: string }) =>
  baseAPIFactory.get<deviceCategory[]>(END_POINTS.deciceCategory(), handleTokenHeaders({ token }));

export const updateStatus = async (data: any, token: string) =>
  baseAPIFactory.put(END_POINTS.UPDATE_IMPORTS(data.record_id), data, handleTokenHeaders({ token }));

export const getAllLonerDetails = async (
  accountId: string | number,
  token: string,
  status: string,
  deviceStatus: string,
  props: FetchParamTypesWithPage
) => {
  const params: { [key: string]: any } = {};
  if (props.page) {
    params.page_no = props.page;
  }
  if (props.contains) {
    params.contains = props.contains;
  }
  if (props.search_field && props.search_value) {
    params.search_field = props.search_field;
    params.search_value = props.search_value;
  }
  const newStatus = status === 'Active' ? 'Active' : 'Retired';
  return baseAPIFactory.get<DeviceInfo[]>(
    END_POINTS.FETCH_ALL_LONER_ACCOUNT_STATUS(accountId, newStatus, deviceStatus),
    {
      params,
      ...handleTokenHeaders({ props, token }),
    }
  );
};
export const getAllDeviceLonerDetails = async (
  accountId: string | number,
  token: string,
  status: string,
  deviceStatus: string,
  props: FetchParamTypesWithPage
) => {
  const params: { [key: string]: any } = {};
  const newStatus = status === 'Active' ? 'Active' : 'Retired';
  return baseAPIFactory.get<DeviceInfo[]>(
    END_POINTS.FETCH_ALL_DEVICES_LONER_ACCOUNT(accountId, newStatus, deviceStatus),
    {
      params,
      ...handleTokenHeaders({ props, token }),
    }
  );
};
export const getAggregatedData = async (props: { token: string }) => {
  const params: { [key: string]: any } = {};
  return baseAPIFactory.get<PagedResponse<DeviceInfo>>(END_POINTS.FETCH_ALL_AGGREGATED(), {
    params,
    ...handleTokenHeaders(props),
  });
};

export const getAssetsByAccount = async ({ accountId, token }: { accountId: string | number; token: string }) =>
  baseAPIFactory.get<deviceCategory[]>(END_POINTS.getAssetsByAccount(accountId), handleTokenHeaders({ token }));

export const getDeviceList = async ({ token }: { token: string }) =>
  baseAPIFactory.get<TransformedQueryResult>(END_POINTS.DEVICE_LIST(), handleTokenHeaders({ token }));
