import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga4';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import SidebarLayout from '../infrastructure/layout/sidebar-main.layout';
import TopAppBar from '../creative_commons/topBarContainer/topAppBar.component';
import { getStringFeatureFlagValue } from '../utils/LaunchDarklyQuery'; // Adjusted import for string value retrieval
import styles from './LayoutStyles';

ReactGA.initialize('G-4JCP2YVCYR');
ReactGA.send('pageview');

const Layout = () => {
  const { isAuthenticated } = useAuth0();
  const [topAppBarContent, setTopAppBarContent] = useState('');

  useEffect(() => {
    const fetchFeatureFlag = async () => {
      const flagValue = await getStringFeatureFlagValue('dream-dynamic-banner');
      setTopAppBarContent(flagValue || '');
    };

    fetchFeatureFlag();
  }, []);

  if (isAuthenticated) {
    return (
      <SidebarLayout>
        <Box sx={styles.mainLayout}>
          {topAppBarContent && (
            <Box>
              <TopAppBar content={topAppBarContent} />
            </Box>
          )}
          <Box>
            <Outlet />
          </Box>
        </Box>
      </SidebarLayout>
    );
  }
  return <Outlet />;
};

export default Layout;
