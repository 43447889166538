import baseAPIFactory, { handleTokenHeaders, TokenParams } from '../base';

export type FetchParamTypes = {
  token: string;
  page?: number | string;
  search_value?: string;
  search_field?: string;
};
export type FetchParamTypesNoPage = {
  token: string;
};

export type FetchParamTypesWithPage = {
  token?: string;
  page?: number;
  search_field?: string;
  search_value?: number | string;
  sort?: string;
  contains?: string;
};

export type FetchParamTypesWithPageAccount = {
  token?: string;
  page?: number;
  search_field?: string;
  search_value?: number | string;
  sort?: string;
  account: number;
  contains?: string;
};

export type AccountCreateInitialType = {
  full_name: string;
  title: string | null;
  email: string | null;
  phone: string | null;
  cell_number: string | null;
  account: string;
  profile_image: any | null;
  user_status: string | null;
  user_role: string | null;
  vivacity_news: boolean | null;
  product_updates: boolean | null;
  vivacity_weekly: boolean | null;
  in_house_repair: boolean | null;
  user_notifications: boolean | null;
  beta_tester: boolean | null;
  building: string | null;
} & TokenParams;

export type AccountItem = {
  full_name: string;
  title: string;
  email: string;
  phone: string;
  profile_image: any;
  user_status: string;
  cell_number: string;
  user_role: number;
  account: number;
  user_account: string;
  subscription_tier: string;
};

export type AccountDetails = {
  account_number: number;
  account_name: string;
  phone_number: string;
  email: string;
  domain: string;
  credit_limit: string;
  account_balance: string;
  account_manager: number;
  account_site: number;
  territory: number;
  payment_terms: string | null;
  subscription_tier: string;
};

export type AccountBuilding = {
  record_id: number;
  building_name: string;
  building_image: string | null;
  phone: string | null;
  extension: string | null;
  default_building: boolean;
  dashboard_color: string | null;
  shipping_address_record_id: number;
  shipping_address_address_1: string;
  shipping_address_address_2: string;
  shipping_address_city: string;
  shipping_address_us_state: string;
  shipping_address_zip: number;
  shipping_address_country: string;
  billing_address_record_id: number;
  billing_address_address_1: string;
  billing_address_address_2: string;
  billing_address_city: string;
  billing_address_us_state: string;
  billing_address_zip: number;
  billing_address_country: string;
};

export type USER_PROFILE = {
  full_name: string;
  title: string;
  email: string;
  extension: string;
  phone: string;
  profile_image: any;
  cell_number: string;
  user_status: string;
  user_role: number;
  account: number;
  created_by: number;
  last_modified_by: number;
  in_house_repair: boolean;
  vivacity_news: boolean;
  product_updates: boolean;
  vivacity_weekly: boolean;
  user_notifications: boolean;
  beta_tester: boolean;
  token?: string;
};
export interface AccountData {
  account_name: string;
  phone_number: string;
  email: string;
  domain: string;
  credit_limit: number | null;
  account_credit: number | null;
  account_manager: number | null;
  account_site: number | null;
  in_house_repairs: boolean;
  territory: number | null;
  payment_terms: string;
  last_modified_by: string;
  tadabase_account_number: string | null;
  netsuite_id: number | null;
  account_paid_tier: string | null;
  subscription_tier: string | null;
}

export type twoFactorAuth = {
  token?: string;
};

const END_POINTS = {
  ACCOUNT_MANAGER: () => '/account/manager',
  FETCH_ALL_ACCOUNT: () => `/account/`,
  FETCH_ALL_ACCOUNT_NO_PAGE: () => `/account/getAll/noPage`,
  FETCH_ALL: (account: string | number) => `/account/contact/get_all/${account}`,
  CREATE_ACCOUNT: () => `/account/contact/`,
  DELETE_ITEM: (itemId: string | number | null) => `/account/contact/${itemId}`,
  GET_ACCOUNT_DETAILS: (emailAddress: string | null) => `/account/contact/account_details/${emailAddress}`,
  GET_ADDRESS_DETAILS: (itemId: string | number | null) => `account/building/${itemId}`,
  GET_DASHBOARD_DATA: (accountId: number | undefined) => `/account/${accountId}/dashboard`,
  GET_ITEM: (itemId: string | number | null) => `/account/contact/${itemId}`,
  RESET_GAC_TOKEN: (recordId?: string | number) => `/account/contact/reset-gac-token/${recordId}`,
  TOGGLE_TWO_FACTOR_AUTH: () => '/user/toggle-mfa',
  UPDATE_ACCOUNT_CREDIT: (itemId: string | number | null) => `/account/update_account_credit/${itemId}`,
  UPDATE_ACCOUNT_IN_BULK: () => '/account/bulk',
  UPDATE_IMG_USER: (recordId?: string | number) => `/user/${recordId}`,
  UPDATE_ITEM: (itemId: string | number | null) => `/account/contact/${itemId}`,
  UPDATE_SUBSCRIPTION: (itemId: string | number | null) => `/account/update_subscription_tier/${itemId}`,
  UPDATE_USER: (recordId?: string | number) => `/account/contact/${recordId}`,
  START_TRIAL_TIER: (accountNumber: string | number) => `/account/start-trial/${accountNumber}`,
};

export const getAccountDashboardData = async ({
  accountId,
  token,
}: {
  accountId: number | undefined;
  token?: string;
}) => baseAPIFactory.get(END_POINTS.GET_DASHBOARD_DATA(accountId), handleTokenHeaders({ token }));

export const getPaginatedList = async (props: FetchParamTypesWithPageAccount) => {
  const params: { [key: string]: any } = {};
  if (props.page) {
    params.page_no = props.page;
  }
  if (props.contains) {
    params.contains = props.contains;
  }
  if (props.search_field && props.search_value) {
    params.search_field = props.search_field;
    params.search_value = props.search_value;
  }
  return baseAPIFactory.get<AccountItem[]>(END_POINTS.FETCH_ALL(props.account), {
    params,
    ...handleTokenHeaders(props),
  });
};

export const getPaginatedAccountList = async (props: FetchParamTypes) => {
  const params: { [key: string]: any } = {};
  if (props.page) {
    params.page_no = props.page;
  }
  return baseAPIFactory.get<AccountDetails[]>(END_POINTS.FETCH_ALL_ACCOUNT(), { params, ...handleTokenHeaders(props) });
};
export const getDefaultBuilding = async ({ account, token }: { account: string | number; token: string }) =>
  baseAPIFactory.get<any>(`/account/building/default_building/${account}`, handleTokenHeaders({ token }));

export const getAllAccounts = async (props: FetchParamTypes) => {
  const params: { [key: string]: any } = {};
  if (props.page) {
    params.page_no = props.page;
  }
  if (props.search_field && props.search_value) {
    params.search_field = props.search_field;
    params.search_value = props.search_value;
  }
  return baseAPIFactory.get<AccountDetails[]>(END_POINTS.FETCH_ALL_ACCOUNT(), {
    params,
    ...handleTokenHeaders(props),
  });
};

export const updateAccountInBulk = async (users: AccountData[], props: TokenParams) => {
  const response = await baseAPIFactory.post(
    END_POINTS.UPDATE_ACCOUNT_IN_BULK(),
    { users },
    {
      ...handleTokenHeaders(props),
    }
  );
  return response.status;
};

export const getAllAccountsNoPage = async (token: string) =>
  baseAPIFactory.get<AccountDetails[]>(END_POINTS.FETCH_ALL_ACCOUNT_NO_PAGE(), handleTokenHeaders({ token }));

export const getAccountManager = async (token: string) =>
  baseAPIFactory.get(END_POINTS.ACCOUNT_MANAGER(), handleTokenHeaders({ token }));

export const createAdminAccount = async (data: any, token: string) =>
  baseAPIFactory.post(END_POINTS.FETCH_ALL_ACCOUNT(), data, handleTokenHeaders({ token }));

export const createAccount = async (data: AccountCreateInitialType) =>
  baseAPIFactory.post(END_POINTS.CREATE_ACCOUNT(), data, handleTokenHeaders(data));

export const updateAccount = async (data: { recordId?: string | number; updateData: any }) => {
  const response = await baseAPIFactory.put(END_POINTS.UPDATE_USER(data?.recordId), data.updateData, {
    ...handleTokenHeaders(data.updateData),
  });

  return response;
};
export const updateAccountImg = async (data: { recordId?: string | number; updateData: any }) => {
  const formData = new FormData();
  formData.append('profile_image', data.updateData.uploadImage);
  const response = await baseAPIFactory.put(END_POINTS.UPDATE_IMG_USER(data?.recordId), formData, {
    ...handleTokenHeaders(data.updateData),
  });

  return response;
};

export const fetchAccountById = async ({ itemId, token }: { itemId: string | number | null; token?: string }) =>
  baseAPIFactory.get<AccountItem[]>(END_POINTS.GET_ITEM(itemId), handleTokenHeaders({ token }));

export const fetchAccountDetailsByEmailId = async ({
  emailAddress,
  token,
}: {
  emailAddress?: string | any;
  token?: string;
}) => baseAPIFactory.get<any>(END_POINTS.GET_ACCOUNT_DETAILS(emailAddress), handleTokenHeaders({ token }));

export const fetchAccountBuildingById = async ({ itemId, token }: { itemId: string | number | null; token?: string }) =>
  baseAPIFactory.get<AccountBuilding[]>(END_POINTS.GET_ADDRESS_DETAILS(itemId), handleTokenHeaders({ token }));

export const deleteAccountById = async (recordId: string | number | null, token: any) =>
  baseAPIFactory.delete(END_POINTS.DELETE_ITEM(recordId), handleTokenHeaders({ token }));

export const updateAccountById = async (recordId: string | number | null, data: any, token: any) => {
  baseAPIFactory.put(END_POINTS.UPDATE_ITEM(recordId), data, handleTokenHeaders({ token }));
};

export const updateSubscriptionById = async (recordId: string | number | null, data: any, token: any) => {
  baseAPIFactory.put(END_POINTS.UPDATE_SUBSCRIPTION(recordId), data, handleTokenHeaders({ token }));
};

export const updateAccountCById = async (recordId: string | number | null, data: any, token: any) => {
  baseAPIFactory.put(END_POINTS.UPDATE_ACCOUNT_CREDIT(recordId), data, handleTokenHeaders({ token }));
};

export const toggleTwoFactorAuth = async (data: twoFactorAuth) =>
  baseAPIFactory.post(END_POINTS.TOGGLE_TWO_FACTOR_AUTH(), {}, handleTokenHeaders(data));

export const resetGacToken = async (userId: number, token: string) =>
  baseAPIFactory.post(END_POINTS.RESET_GAC_TOKEN(userId), {}, { ...handleTokenHeaders({ token }) });

export const startTrialTierAccount = async ({
  accountNumber,
  token,
}: {
  accountNumber: string | number;
  token?: string;
}) => baseAPIFactory.put(END_POINTS.START_TRIAL_TIER(accountNumber), {}, handleTokenHeaders({ token }));
