import React, { createContext, useContext, useState, useRef, useMemo, ReactNode } from 'react';

interface FilterContextProps {
  gridRef: React.MutableRefObject<any>;
  filtersActive: boolean;
  setFiltersActive: (active: boolean) => void;
  resetFilters: () => void;
}

const FilterContext = createContext<FilterContextProps | undefined>(undefined);

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterProvider');
  }
  return context;
};

interface FilterProviderProps {
  children: ReactNode;
}

export const FilterProvider: React.FC<FilterProviderProps> = ({ children }) => {
  const gridRef = useRef<any>(null);
  const [filtersActive, setFiltersActive] = useState(false);

  const resetFilters = () => {
    if (gridRef.current) {
      gridRef.current.setFilterModel(null);
      setFiltersActive(false);
    }
  };

  const contextValue = useMemo(
    () => ({
      gridRef,
      filtersActive,
      setFiltersActive,
      resetFilters,
    }),
    [filtersActive]
  );

  return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>;
};
