import React, { lazy } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import Layout from '../components/Layout';
import ProtectedRoute from '../components/routes/ProtectedRoute';
import {
  HOME_ROUTE,
  ROADMAP_ROUTE,
  STYLE_GUIDE,
  REPORT_CENTER_ROUTE,
  ACCOUNT_DASHBOARD_ROUTE,
  REPAIR_CENTER_BOX_DETAIL_ROUTE,
  REPAIR_CENTER_TICKET_DETAIL_ROUTE,
  REPAIR_CENTER_INHOUSE_DETAIL_ROUTE,
  RESOURCES_ROUTE,
  USERS,
  USER,
  ADMIN,
  REPAIR_CENTER_ROUTE,
  MY_BUILDINGS,
  MY_PROFILE,
  MANAGE_DEVICES,
  LOGGED_OUT_ROUTE,
  SUPPORT_ROUTE,
  MANAGE_DEVICE_INFO,
  REPAIR_CENTER_INBOUND_BOX_DETAIL,
  FORBIDDEN_ACCESS,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE,
  GOOGLE_USE_POLICY,
  LOG_IN_ROUTE,
  DEVICE_INFO,
  BLOCKED_USER_PAGE,
} from '../constants/routes';
import { PAGE_NAMES, REPORT_CENTER_SUB_MODULE_MAP } from '../components/roles/constants';
import GuestRoute from '../components/routes/GuestRoute';
import useRoleIdentity from '../components/roles/hooks';

const StyleGuide = lazy(() => import('../styleGuide/styleGuide'));
const HomePage = lazy(() => import('../pages/HomePage'));
const LandingPage = lazy(() => import('../pages/LandingPage'));

const ReportCenterPage = lazy(() => import('../features/report_center/pages/report-center.main.page'));
const RepairCenterPage = lazy(() => import('../features/repair/pages/repair-center.main.page'));
const VivacityRepairBoxDetailPage = lazy(() => import('../features/repair/pages/vivacity-repair-box-detail.page'));
const VivacityRepairInboundBoxDetailPage = lazy(
  () => import('../features/repair/pages/vivacity-repair-inbound-box-detail.page')
);
const VivacityRepairTicketDetailPage = lazy(
  () => import('../features/repair/repair_ticket_detail/vivacity-repair-ticket-detail.page')
);
const VivacityInHouseTicketDetailPage = lazy(
  () => import('../features/repair/pages/vivacity-inhouse-ticket-detail.page')
);
const RoadMapPage = lazy(() => import('../features/roadmap/pages/roadmap.page'));
const ResourcesPage = lazy(() => import('../features/resources/pages/resources-main.page'));
const AccountDashboardPage = lazy(() => import('../features/account_dashboard/pages/account-dashboard.main.page'));
const BuildingDashboardPage = lazy(() => import('../features/my_buildings/pages/building-dashboard.main.page'));
const ProfileDashboardPage = lazy(() => import('../features/my_profile/pages/my-profile.main.page'));
const ManageDevicesPage = lazy(() => import('../features/my_devices/pages/manage_devices'));
const DeviceInfoPage = lazy(() => import('../features/my_devices/pages/device_info.page'));
const ManageGACDeviceInfoPage = lazy(() => import('../features/my_devices/pages/gac_device_info.page'));
const DeviceRepairTicketsPage = lazy(() => import('../features/my_devices/pages/device_repair_tickets.page'));
const DeviceAssigmentsHistoryPage = lazy(() => import('../features/my_devices/pages/device_assignments_history.page'));
const SupportPage = lazy(() => import('../features/support/pages/support.main.page'));
const Users = lazy(() => import('../features/users/pages/usersWrapper'));
const User = lazy(() => import('../features/users/pages/user.page'));
const VivacityAdmin = lazy(() => import('../features/admin/pages/admin.main.page'));
const NoAccessPage = lazy(() => import('../pages/NoAccessPage'));
const UserBlockedAccessPage = lazy(() => import('../pages/UserBlockedAccessPage'));
const PrivacyPolicyPage = lazy(() => import('../pages/PrivacyPolicyPage'));
const TermsOfServicePage = lazy(() => import('../pages/TermsOfServicePage'));
const DisclosureGooglePage = lazy(() => import('../pages/GoogleDisclosurePage'));

const Routes = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const userRoleIdentity = useRoleIdentity(PAGE_NAMES.REPORT_CENTER);
  const DefaultAuthenticatedLandingPage = userRoleIdentity.canView(REPORT_CENTER_SUB_MODULE_MAP.VIVACITY_REPAIR_REPORT)
    ? REPORT_CENTER_ROUTE
    : REPAIR_CENTER_ROUTE;

  return (
    <Switch>
      {!isLoading && isAuthenticated ? (
        <Route path={HOME_ROUTE} element={<Layout />}>
          <Route path="" element={<Navigate to={DefaultAuthenticatedLandingPage} />} />
          <Route
            path={STYLE_GUIDE}
            element={
              <ProtectedRoute>
                <StyleGuide />
              </ProtectedRoute>
            }
          />
          <Route
            path={STYLE_GUIDE}
            element={
              <ProtectedRoute>
                <StyleGuide />
              </ProtectedRoute>
            }
          />
          <Route
            path={REPORT_CENTER_ROUTE}
            element={
              <ProtectedRoute>
                <ReportCenterPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={REPAIR_CENTER_ROUTE}
            element={
              <ProtectedRoute name={PAGE_NAMES.REPAIR_360}>
                <RepairCenterPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={REPAIR_CENTER_BOX_DETAIL_ROUTE}
            element={
              <ProtectedRoute name={PAGE_NAMES.REPAIR_360}>
                <VivacityRepairBoxDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={REPAIR_CENTER_INBOUND_BOX_DETAIL}
            element={
              <ProtectedRoute name={PAGE_NAMES.REPAIR_360}>
                <VivacityRepairInboundBoxDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={REPAIR_CENTER_TICKET_DETAIL_ROUTE}
            element={
              <ProtectedRoute name={PAGE_NAMES.REPAIR_360}>
                <VivacityRepairTicketDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={REPAIR_CENTER_INHOUSE_DETAIL_ROUTE}
            element={
              <ProtectedRoute name={PAGE_NAMES.REPAIR_360}>
                <VivacityInHouseTicketDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ACCOUNT_DASHBOARD_ROUTE}
            element={
              <ProtectedRoute>
                <AccountDashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ADMIN}
            element={
              <ProtectedRoute name={PAGE_NAMES.VIVACITY_ADMIN_PAGE}>
                <VivacityAdmin />
              </ProtectedRoute>
            }
          />
          <Route
            path={USERS}
            element={
              <ProtectedRoute name={PAGE_NAMES.USERS}>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path={USER}
            element={
              <ProtectedRoute name={PAGE_NAMES.USER}>
                <User />
              </ProtectedRoute>
            }
          />
          <Route
            path={SUPPORT_ROUTE}
            element={
              <ProtectedRoute>
                <SupportPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={RESOURCES_ROUTE}
            element={
              <ProtectedRoute name={PAGE_NAMES.RESOURCES}>
                <ResourcesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROADMAP_ROUTE}
            element={
              <ProtectedRoute name={PAGE_NAMES.FEATURES}>
                <RoadMapPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={MY_BUILDINGS}
            element={
              <ProtectedRoute name={PAGE_NAMES.MY_BUILDINGS}>
                <BuildingDashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={MY_PROFILE}
            element={
              <ProtectedRoute name={PAGE_NAMES.MY_PROFILE}>
                <ProfileDashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={MANAGE_DEVICES}
            element={
              <ProtectedRoute name={PAGE_NAMES.MANAGE_DEVICES}>
                <ManageDevicesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={DEVICE_INFO}
            element={
              <ProtectedRoute>
                <DeviceInfoPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${DEVICE_INFO}/tickets`}
            element={
              <ProtectedRoute>
                <DeviceRepairTicketsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${DEVICE_INFO}/assignments`}
            element={
              <ProtectedRoute>
                <DeviceAssigmentsHistoryPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${MANAGE_DEVICE_INFO}/:deviceId`}
            element={
              <ProtectedRoute>
                <ManageGACDeviceInfoPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={FORBIDDEN_ACCESS}
            element={
              <ProtectedRoute>
                <NoAccessPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            }
          />
          <Route path={PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
          <Route path={TERMS_OF_SERVICE} element={<TermsOfServicePage />} />
          <Route path={GOOGLE_USE_POLICY} element={<DisclosureGooglePage />} />
          <Route path={BLOCKED_USER_PAGE} element={<UserBlockedAccessPage />} />
        </Route>
      ) : (
        !isLoading && (
          <>
            <Route path={HOME_ROUTE} element={<LandingPage />} />
            <Route path={HOME_ROUTE} element={<Layout />}>
              <Route
                path={LOG_IN_ROUTE}
                element={
                  <ProtectedRoute>
                    <HomePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={LOGGED_OUT_ROUTE}
                element={
                  <GuestRoute>
                    <LandingPage />
                  </GuestRoute>
                }
              />
              <Route path={HOME_ROUTE} element={<Layout />}>
                <Route
                  path="/*"
                  element={
                    <GuestRoute>
                      <LandingPage />
                    </GuestRoute>
                  }
                />
              </Route>
              <Route path={PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
              <Route path={TERMS_OF_SERVICE} element={<TermsOfServicePage />} />
              <Route path={GOOGLE_USE_POLICY} element={<DisclosureGooglePage />} />
              <Route path={BLOCKED_USER_PAGE} element={<UserBlockedAccessPage />} />
            </Route>
          </>
        )
      )}
    </Switch>
  );
};

export default Routes;
