export const AUTH_TOKEN_KEY = 'userAuthToken';
export const AUTH_RESPONSE_STORE_NAME = 'userAuthStorageData';
export const STORAGE_CONTEXT_NAME = 'userLocalStorageLocal';
export const USER_STATUS_NEW = 'New';
export const USER_STATUS_REJECTED = 'Rejected';
export const USER_STATUS_ACTIVE = 'Active';

export const ADMIN_PAID_USER_PER_PAGE = 10;

export const PRELOAD_DARKLY_FEATURES = [
  'spoofing-login-beta',
  'sra-student-flow-flag',
  'dream-banner-error',
  'ag-grid-tour-modal',
];

export const FETCH_ROLES_PAGE_SIZE = 30;

export const LINE_CHART_DATA_MINIMUM = 2;

export const STORAGE_SIZES = [16.0, 32.0, 64.0, 128.0, 256.0, 512.0];

export const HORIZONTAL_GUTTER_SPACING = 2;
export const VERTICAL_GUTTER_SPACING = 1;

export const GOOGLE_MAP_ID = '29df7aad448e0ebb';
