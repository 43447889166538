import baseAPIFactory, { handleTokenHeaders } from '../base';

const END_POINTS = {
  SERVICE_ACCOUNT: (accountNumber: number) => `/customer/${accountNumber}/service-account`,
  SERVICE_ACCOUNT_GOOGLE_PROJECT: (accountNumber: number, recordId: number) =>
    `/customer/${accountNumber}/service-account/${recordId}/google/project`,
  SERVICE_ACCOUNT_GOOGLE_PROJECT_SERVICE_ACCOUNT: (accountNumber: number, recordId: number) =>
    `/customer/${accountNumber}/service-account/${recordId}/google/project/service-account`,
  SERVICE_ACCOUNT_GOOGLE_PROJECT_SERVICE_ACCOUNT_SECRET_KEY: (accountNumber: number, recordId: number) =>
    `/customer/${accountNumber}/service-account/${recordId}/google/project/service-account/secret-key`,
  SERVICE_ACCOUNT_TEST_ACCESS: (accountNumber: number, recordId: number) =>
    `/customer/${accountNumber}/service-account/${recordId}/test-access`,
};

export type ServiceAccount = {
  record_id?: number;
  account_number: number;
  project_id?: string;
  username?: string;
  email?: string;
  display_name?: string;
  description?: string;
  created_at?: Date;
  updated_at?: Date;
  last_tested_at?: Date;
  last_test_result?: boolean;
  access_token?: string;
  current_state?: string;
  errors?: string[];
};

export const fetchServiceAccountByAccountNumber = async ({
  accountNumber,
  token,
}: {
  accountNumber: number;
  token?: string;
}) => baseAPIFactory.get<ServiceAccount>(END_POINTS.SERVICE_ACCOUNT(accountNumber), handleTokenHeaders({ token }));

export const createServiceAccountByAccountNumber = async ({
  accountNumber,
  serviceAccount,
  token,
}: {
  accountNumber: number;
  serviceAccount: ServiceAccount;
  token?: string;
}) =>
  baseAPIFactory.post<ServiceAccount>(
    END_POINTS.SERVICE_ACCOUNT(accountNumber),
    serviceAccount,
    handleTokenHeaders({ token })
  );

export const createServiceAccountGoogleProjectByAccountNumber = async ({
  accountNumber,
  recordId,
  serviceAccount,
  token,
}: {
  accountNumber: number;
  recordId: number;
  serviceAccount: ServiceAccount;
  token?: string;
}) =>
  baseAPIFactory.post<ServiceAccount>(
    END_POINTS.SERVICE_ACCOUNT_GOOGLE_PROJECT(accountNumber, recordId),
    serviceAccount,
    handleTokenHeaders({ token })
  );

export const createServiceAccountGoogleProjectServiceAccountByAccountNumber = async ({
  accountNumber,
  recordId,
  serviceAccount,
  token,
}: {
  accountNumber: number;
  recordId: number;
  serviceAccount: ServiceAccount;
  token?: string;
}) =>
  baseAPIFactory.post<ServiceAccount>(
    END_POINTS.SERVICE_ACCOUNT_GOOGLE_PROJECT_SERVICE_ACCOUNT(accountNumber, recordId),
    serviceAccount,
    handleTokenHeaders({ token })
  );

export const createServiceAccountGoogleProjectServiceAccountSecretKeyByAccountNumber = async ({
  accountNumber,
  recordId,
  serviceAccount,
  token,
}: {
  accountNumber: number;
  recordId: number;
  serviceAccount: ServiceAccount;
  token?: string;
}) =>
  baseAPIFactory.post<ServiceAccount>(
    END_POINTS.SERVICE_ACCOUNT_GOOGLE_PROJECT_SERVICE_ACCOUNT_SECRET_KEY(accountNumber, recordId),
    serviceAccount,
    handleTokenHeaders({ token })
  );

export const serviceAccountTestAccess = async ({
  accountNumber,
  recordId,
  token,
}: {
  accountNumber: number;
  recordId: number;
  token?: string;
}) =>
  baseAPIFactory.get<ServiceAccount>(
    END_POINTS.SERVICE_ACCOUNT_TEST_ACCESS(accountNumber, recordId),
    handleTokenHeaders({ token })
  );
