import { get } from 'lodash';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RecordType, isAPIResponseFailure, PaginatedResponse } from '../api/base';
import { RootState } from '../store';
import appLogger from '../infrastructure/config/appLogger';
import { setLoading } from './commonSlice';
import { listStudents, createStudent, getStudentByAccount } from '../api/student';
import type { CreateStudentData, Student, StudentInfo, StudentByAccount } from '../api/student';
import {
  UsersByAccount,
  WarrantyType,
  fetchNewUsers,
  fetchWarranty,
  FetchParamTypesWithPage,
  FetchParamTypesWithNewUser,
  getAllAdminWarranty,
} from '../api/user/user';
import { PER_PAGE_RECORD_DEFAULT, PER_PAGE_RECORD_100 } from '../constants/data/account.constants';

const sliceName = 'student';

type TokenParam = {
  token: string;
};
type StudentTokenParam = {
  token: string;
  accountNumber: any;
};

type StudentData = PaginatedResponse<Student> & {
  totalPages: number | string;
};

export interface InitialState {
  studentList: Student[];
  getStudentByAccount: Student[];
  page: number;
  perPage: number;
  totalCount: number;
  totalPages: number;
  totalcountPages: number;
  totalpageStudent: number;
  totalPagesNewUser: number;
  allStudentList: StudentInfo[];
  warranties: WarrantyType[];
  newUser: any[];
  users: any[];
  allStudent: Student[];
  studentInformation: StudentData;
  loading: boolean;
  warrantyAdminData: WarrantyType[];
}

const initialState: InitialState = {
  studentList: [],
  getStudentByAccount: [],
  page: 1,
  perPage: PER_PAGE_RECORD_DEFAULT,
  totalCount: 0,
  totalPages: 0,
  totalcountPages: 0,
  totalpageStudent: 0,
  totalPagesNewUser: 0,
  allStudentList: [],
  warranties: [],
  newUser: [],
  users: [],
  allStudent: [],
  warrantyAdminData: [],
  loading: false,
  studentInformation: {
    rows: [],
    totalPages: 0,
    total: 0,
  },
};

export const fetchStudentsList = createAsyncThunk<Student[], TokenParam>(
  `${sliceName}/fetchStudentsList`,
  async (params, ThunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/fetchStudentsList`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const studentList = await listStudents(params.token);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(studentList)) {
        return ThunkAPI.rejectWithValue(studentList);
      }
      return studentList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);
export const getStudentsByAccount = createAsyncThunk<StudentByAccount[], StudentTokenParam>(
  `${sliceName}/fetch`,
  async (params, ThunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const studentList = await getStudentByAccount(params.token, params.accountNumber);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(studentList)) {
        return ThunkAPI.rejectWithValue(studentList);
      }
      return studentList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);
export const fetchStudents = createAsyncThunk<StudentInfo[], FetchParamTypesWithPage>(
  `${sliceName}/fetchAll`,
  async () => []
);

export const postNewStudent = createAsyncThunk<RecordType | null, CreateStudentData>(
  `${sliceName}/create`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/fetch`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await createStudent(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
      throw e;
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
  }
);

export const fetchWarranties = createAsyncThunk<WarrantyType[], FetchParamTypesWithPage>(
  `${sliceName}/fetchWarranty`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchWarranty`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await fetchWarranty(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchGetNewUser = createAsyncThunk<WarrantyType[], FetchParamTypesWithNewUser>(
  `${sliceName}/fetchNewUser`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchNewUser`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await fetchNewUsers(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllWarranties = createAsyncThunk<WarrantyType[], FetchParamTypesWithPage>(
  `${sliceName}/fetchAllWarranty`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchAllWarranty`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await getAllAdminWarranty(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

type fetchUsersWithToken = {
  token: string;
  recordId: any;
};

export const fetchUsersByAccount = createAsyncThunk<any[], fetchUsersWithToken>(
  `${sliceName}/fetchUser`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchUser`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await UsersByAccount(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

const studentSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStudentsList.fulfilled, (state, action) => {
      const { payload } = action;
      const totalRecords = parseInt(get(payload, 'total') || '0', 10) || 0;
      state.studentInformation = {
        rows: get(payload, 'rows') || [],
        total: totalRecords,
        totalPages: Math.ceil(totalRecords / PER_PAGE_RECORD_DEFAULT),
      };
    });
    builder.addCase(getStudentsByAccount.fulfilled, (state, action) => {
      const { payload } = action;
      state.getStudentByAccount = payload;
    });
    builder.addCase(fetchWarranties.fulfilled, (state, action) => {
      const { payload } = action;
      state.warranties = get(payload, 'rows', []);
      const totalCount = get(payload, 'total', 0);
      state.totalPages = Math.ceil(totalCount / state.perPage);
    });
    builder.addCase(fetchGetNewUser.fulfilled, (state, action) => {
      const { payload } = action;
      state.newUser = get(payload, 'rows', []);
      const totalCount = get(payload, 'total', 0);
      state.totalPagesNewUser = Math.ceil(totalCount / state.perPage);
    });
    builder.addCase(fetchStudents.fulfilled, (state, action) => {
      const { payload } = action;
      state.allStudent = get(payload, 'rows', []);
      const totalcountPages = get(payload, 'total', 0);
      state.totalpageStudent = Math.ceil(totalcountPages / PER_PAGE_RECORD_100);
    });
    builder.addCase(fetchUsersByAccount.fulfilled, (state, action) => {
      const { payload } = action;
      state.users = payload;
    });
    builder.addCase(fetchAllWarranties.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllWarranties.fulfilled, (state, action) => {
      state.warrantyAdminData = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchAllWarranties.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default studentSlice.reducer;

export const studentStateItem = createSelector(
  (state: RootState) => state.student,
  (items: InitialState) => items
);

export const getStudentUsersStateItem = createSelector(
  (state: RootState) => state.student.users,
  (items: any[]) => (Array.isArray(items) ? items : [])
);

export const studentInformation = createSelector(
  (state: RootState) => state.student.studentInformation,
  (items: StudentData) => items
);

export const getAllStudentState = createSelector(
  (state: RootState) => state.student.allStudent,
  (items: any) => (Array.isArray(items) ? items : [])
);
