import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useTokenCallBack, useAppSelector } from '../../store/hooks';
import { addPaidSubscriptionRequest, VivacityAdminData } from '../../api/vivacityAdmin';
import { accountStateItem } from '../../slices/accountSlice';
import { getUserAuthData } from '../../api/user/user';
import DialogWrapper from './DialogWrapper';
import { DefaultButton } from '../StyledComponent';
import paidDialogStyles from './PaidDialogStyles';

interface Props {
  open: boolean;
  setResponseMessage: any;
  handleClose: (event: any) => void;
  subscriptionTier: string;
}

const PaidDialog = ({ open, setResponseMessage, handleClose, subscriptionTier }: Props) => {
  const [comments, setComments] = useState('');
  const makeTokenCall = useTokenCallBack();
  const { accountDetails } = useAppSelector(accountStateItem);
  const styles = paidDialogStyles;

  const handleError = (message) => {
    setResponseMessage((prevState: any) => ({
      ...prevState,
      responseMessage: message,
      isResponseSuccessful: false,
    }));
    handleClose(true);
  };

  const saveRequest = async () =>
    new Promise((resolve, reject) => {
      makeTokenCall(async (token: string) => {
        try {
          const authData: any = await getUserAuthData(token);
          const vivacityAdminData: VivacityAdminData = {
            user_id: authData.logged_in_id,
            account: accountDetails.account_number,
            fulfillment_date: null,
            request_type: subscriptionTier,
            message: comments,
          };
          const response = await addPaidSubscriptionRequest(vivacityAdminData, { token });
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    });

  const handleApply = async () => {
    if (!isEmpty(comments)) {
      try {
        const request: any = await saveRequest();
        if (request.status === 200 && request?.data?.customMessage) {
          setResponseMessage((prevState: any) => ({
            ...prevState,
            responseMessage: request?.data?.customMessage,
            isResponseSuccessful: true,
          }));
          setComments('');
          handleClose(false);
        } else {
          setResponseMessage((prevState: any) => ({
            ...prevState,
            responseMessage: 'Requirement sent successfully.',
            isResponseSuccessful: true,
          }));
          setComments('');
          handleClose(false);
        }
      } catch (error) {
        handleError(error instanceof Error ? error.message : 'An error occurred, please contact support');
      }
    } else {
      handleError('Please add comments to submit the request.');
    }
  };

  const customButton = (
    <DefaultButton data-testid="btnHandleApply" onClick={() => handleApply()}>
      Submit Request
    </DefaultButton>
  );

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={`This feature requires the ${subscriptionTier} Tier`}
      customButton={customButton}
    >
      <Box>
        <Typography sx={styles.subHeading}>
          Please submit a request to upgrade your account and include details of what functionality you want your
          organization account to have.
        </Typography>
        <textarea
          style={styles.textAreaModal}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          placeholder="Add your comments here."
          name="value"
          rows={5}
        />
      </Box>
    </DialogWrapper>
  );
};

export default PaidDialog;
