import * as React from 'react';
import { MouseEvent, useEffect, useMemo, useState, useContext } from 'react';
import isEmpty from 'lodash/isEmpty';
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Avatar, Card, Grid, Modal, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import StarBorderPurple500OutlinedIcon from '@mui/icons-material/StarBorderPurple500Outlined';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CloseIcon from '@mui/icons-material/Close';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { SnackbarContext } from 'src/wrappers/SnackBarWrapper';
import ChangeDashboardColorIcon from '../../assets/images/bottomMenu-change-color-icon.png';
import useStyles, {
  Drawer,
  DrawerHeader,
  chevronButton,
  keyboardIcon,
  labelBoxBorder,
  listItemText,
  mainLinkItem,
  profileListItemIcon,
  drawerHeader,
  listBox,
  profileButton,
} from './sidebar.styles';
import {
  BOTTOM_BAR_ITEMS,
  COPYRIGHT_TEXT,
  COPYRIGHT_YEAR_TEXT,
  DRAWER_VARIANT,
  SIDEBAR_ITEMS,
} from '../../constants/data/menu-items.constants';
import useRoleIdentity, { PAGE_NAMES } from '../../components/roles/hooks';
import { useAppDispatch, useAppSelector, useTokenCallBack } from '../../store/hooks';
import {
  buildingStateItem,
  deleteOneFav,
  fetchBuildingColor,
  fetchFavItems,
  updateBuildingColor,
} from '../../slices/buildingSlice';
import { accountStateItem } from '../../slices/accountSlice';
import { externalUserLoggedInUser, externalUserStateItem, fetchLoggedInUser } from '../../slices/externalUserSlice';
import { useUserDetails } from '../../wrappers/UserDetailWrapper';
import { SUBSCRIPTION_TIER } from '../../constants/data/paid-tier.constants';
import { SIDEBAR_SUB_MODULE_MAP } from '../../components/roles/constants';
import Config from '../../infrastructure/config/config';
import Logo from '../logo/logo.component';
import { LOGGED_OUT_ROUTE } from '../../constants/routes';
import PaidDialog from '../../components/modals/PaidDialog';
import COLORS from '../../constants/colors/colors.constant';
import { DefaultButton } from '../../components/StyledComponent';
import appLogger from '../../infrastructure/config/appLogger';
import { storage } from '../../api/base';
import defaultUserImage from '../../assets/images/user.svg';
import AvatarWithConditionalCrossOrigin from './avatarCross.component';

export default function SidebarDrawer({ children }: any) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const user = useAppSelector(externalUserLoggedInUser) as any;
  const { user: auth0User } = useAuth0();
  const [open, setOpen] = useState(true);
  const roleData = useRoleIdentity(PAGE_NAMES.SIDEBAR);
  const [openBottom, setOpenBottom] = useState(false);
  const dispatch = useAppDispatch();
  const makeTokenCall = useTokenCallBack();
  const config = useMemo(() => Config.getConfig(), []);
  const appConfig = Config.getConfig();
  const { buildingColor, FavItem } = useAppSelector(buildingStateItem);
  const { accountDetails } = useAppSelector(accountStateItem);
  const { loggedInUser } = useAppSelector(externalUserStateItem);
  const externalUser = useAppSelector(externalUserLoggedInUser) as any;
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string>(location.pathname || '/');
  const userDetail = useUserDetails();
  const authData = userDetail.user;
  const [openDashboardColor, setOpenDashboardColor] = React.useState(false);
  const [hoverStateFavorites, setHoverStateFavorites] = useState(false);
  const [hoverStateDashboardColor, setHoverStateDashboardColor] = useState(false);
  const { setSnackBar } = useContext(SnackbarContext);

  const dashboardColorData = React.useMemo(
    () => [
      {
        id: 1,
        title: 'Primary',
        bgColor: buildingColor[0],
      },
      {
        id: 2,
        title: 'Secondary',
        bgColor: buildingColor[1],
      },
    ],
    [buildingColor]
  );
  const [activeModal, setActiveModal] = useState({
    title: dashboardColorData[0].title,
  });

  const handleMouseOverFavorites = () => {
    setHoverStateFavorites(true);
  };

  const handleMouseOutFavorites = () => {
    setHoverStateFavorites(false);
  };

  const handleMouseOverDashboardColor = () => {
    setHoverStateDashboardColor(true);
  };

  const handleMouseOutDashboardColor = () => {
    setHoverStateDashboardColor(false);
  };

  const handleOpenColor = () => setOpenDashboardColor(true);
  const [openModal, setOpenModal] = useState({
    openFavModal: false,
    openPaidTier: false,
  });
  const { logout } = useAuth0();
  const [userProfile, setUserProfile] = useState<any>({});
  const [openFav, setOpenFav] = useState(false);
  const handleOpenFav = () => setOpenFav(true);
  const handleCloseFav = () => setOpenFav(false);
  const validTier = accountDetails?.subscription_tier !== SUBSCRIPTION_TIER.STARTER;

  const handleOpenCloseModal = (key: any, value: any) => setOpenModal((prevState) => ({ ...prevState, [key]: value }));
  const deleteWebpage = (item: any) => {
    makeTokenCall((token: string) => {
      dispatch(
        deleteOneFav({
          recordId: item.record_id,
          token,
        })
      ).then(() => {
        dispatch(fetchFavItems({ userId: authData?.logged_in_id, token }));
      });
    });
  };

  useEffect(() => {
    if (user || auth0User) {
      const imageurl =
        user?.profile_image && user?.profile_image !== '/uploads' ? `${appConfig.BASE_URL}${user?.profile_image}` : '';
      const newurl = imageurl.replace(/\\/g, '/');
      let profileImage = '';
      if (newurl) {
        profileImage = newurl;
      } else if (auth0User?.picture) {
        profileImage = auth0User?.picture;
      }
      const newprofileImage = profileImage.replace(/\\/g, '/');
      setUserProfile((prevState: any) => ({
        ...prevState,
        ...user,
        full_name: user?.full_name || auth0User?.name || '',
        profile_image: newprofileImage,
      }));
    }
  }, [externalUser]);

  useEffect(() => {
    makeTokenCall(async (token: string) => {
      dispatch(fetchLoggedInUser({ userEmail: authData?.email, token }));
    });
  }, []);

  useEffect(() => {
    if (location.pathname !== activeItem) {
      setActiveItem(location.pathname);
    }
  }, [location.pathname]);

  const handleModal = () => {
    setOpenDashboardColor(false);
    handleOpenCloseModal('openFavModal', false);
  };

  const handleColor = (bgColor: string) => {
    if (loggedInUser?.building) {
      const newColor = [...dashboardColorData];
      if (activeModal.title === 'Primary') {
        newColor[0].bgColor = bgColor;
      } else {
        newColor[1].bgColor = bgColor;
      }
      makeTokenCall((token: string) => {
        dispatch(
          updateBuildingColor({
            dashboard_color_primary: dashboardColorData[0].bgColor,
            dashboard_color_secondary: dashboardColorData[1].bgColor,
            itemId: loggedInUser?.building,
            token,
          })
        ).then(() => {
          dispatch(fetchBuildingColor({ itemId: loggedInUser?.building, token }));
        });
      });
      handleOpenCloseModal('openFavModal', false);
    } else {
      setSnackBar({
        message: 'Please update building in your account then you are able to change dashboard color.',
        successful: false,
        open: true,
      });
    }
  };

  const handleOpenPaidDialog = () => {
    handleOpenCloseModal('openPaidTier', true);
  };

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen);
    setOpenBottom(false);
  };

  const toggleBottomMenu = () => {
    setOpenBottom(!openBottom);
  };

  const handleProfileButtonClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (!open) {
      toggleDrawer(true);
    }
    toggleBottomMenu();
  };

  const handleLogout = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    storage.removeKey('spoofingUserDetails');
    storage.removeKey('userToken');
    const logoutURL = `${config.hostName}${LOGGED_OUT_ROUTE}`;
    appLogger.log('Logout URL', logoutURL);
    logout({
      returnTo: logoutURL,
    } as Omit<LogoutOptions, 'onRedirect'>);
  };

  return (
    <Box sx={styles.sideBarFlexBox}>
      <CssBaseline />
      <Modal
        open={openDashboardColor}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Card sx={styles.heading}>
            <Box sx={styles.flexHeading}>
              <Typography sx={styles.subHeading}>Customize Dashboard Color</Typography>
            </Box>
            {dashboardColorData.map((item: any) => (
              <Box sx={styles.label} key={item.id}>
                <Typography sx={styles.pageText}>{`${item.title} color`}</Typography>
                {roleData.canModify(SIDEBAR_SUB_MODULE_MAP.DASHBOARD_COLOR) && (
                  <DefaultButton
                    variant="text"
                    sx={labelBoxBorder({ theme }, activeModal, item)}
                    onClick={() => setActiveModal({ title: item.title })}
                  >
                    <Typography
                      sx={[
                        styles.color,
                        {
                          backgroundColor: item.bgColor,
                        },
                      ]}
                    />
                    <Typography sx={styles.lightColorText}>{item.bgColor}</Typography>
                    <EditIcon onClick={() => handleOpenCloseModal('openFavModal', true)} sx={styles.colorIcon} />
                  </DefaultButton>
                )}
              </Box>
            ))}
          </Card>
          {openModal.openFavModal && (
            <Card sx={styles.cardModal}>
              <Box sx={{ textAlign: 'right' }}>
                <IconButton onClick={() => handleOpenCloseModal('openFavModal', false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Typography sx={styles.modalHeading}>{activeModal.title}</Typography>
              </Box>
              <Box sx={styles.colorBox}>
                {Object.values(COLORS.dashColors).map((color: any) => (
                  <Card
                    key={color}
                    onClick={() => handleColor(color)}
                    sx={[
                      styles.inputColor,
                      {
                        backgroundColor: color,
                      },
                    ]}
                  />
                ))}
              </Box>
            </Card>
          )}
        </Box>
      </Modal>
      <Modal
        open={openFav}
        onClose={handleCloseFav}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Card sx={styles.heading}>
            <Box sx={styles.flexHeading}>
              <Typography sx={styles.subHeading}>Favorite</Typography>
            </Box>
            {isEmpty(FavItem) ? (
              <Box sx={styles.label}>
                <Typography sx={styles.pageText}>No favorite available</Typography>
              </Box>
            ) : (
              <Box sx={styles.scroll}>
                {FavItem?.map((item: any) => (
                  <Box key={item.record_id}>
                    <Grid container alignItems="center">
                      <Grid item sm={10} md={10} xl={10}>
                        <Typography sx={styles.linkText} onClick={handleCloseFav}>
                          <Link to={item.widget_url}>{item.title}</Link>
                        </Typography>
                      </Grid>
                      <Grid item sm={2} md={2} xl={2}>
                        {roleData.canModify(SIDEBAR_SUB_MODULE_MAP.FAVORITE) && (
                          <IconButton onClick={() => deleteWebpage(item)}>
                            <DeleteOutlinedIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            )}
          </Card>
        </Box>
      </Modal>
      <PaidDialog
        open={openModal.openPaidTier}
        setResponseMessage={setOpenModal}
        subscriptionTier={SUBSCRIPTION_TIER.PROFESSIONAL}
        handleClose={(action) => handleOpenCloseModal('openPaidTier', action)}
      />
      <Drawer variant={DRAWER_VARIANT} open={open}>
        <DrawerHeader sx={drawerHeader(open)}>
          <Logo sx={!open ? styles.logo : styles.logoOnHover} />
        </DrawerHeader>
        <Divider />
        <List disablePadding>
          {SIDEBAR_ITEMS.map((item) => {
            const allowView = roleData.handleMenuData(item);
            return (
              allowView && (
                <ListItem key={item.id} disablePadding sx={styles.listItemBlock}>
                  <NavLink
                    to={item.route}
                    target={item.newTab ? '_blank' : ''}
                    rel={item.newTab ? 'noopener noreferrer' : ''}
                    style={({ isActive }) => mainLinkItem(open, theme, isActive)}
                  >
                    <ListItemButton sx={listBox(theme, open)}>
                      <Tooltip title={open ? '' : item.name} placement="right-end">
                        <div style={open ? styles.openListIcon : styles.closedListIcon}>{item.icon}</div>
                      </Tooltip>
                      <Box>
                        <span style={listItemText(open)}>{item.name}</span>
                        {item.subText && (
                          <Typography sx={[listItemText(open), open ? styles.subTextStyle : styles.closedListIcon]}>
                            {item.subText}
                            <span style={styles.accountValue}>{accountDetails?.account_credit}</span>
                          </Typography>
                        )}
                      </Box>
                    </ListItemButton>
                  </NavLink>
                </ListItem>
              )
            );
          })}
          <Divider />
          <ListItem key="favorite" disablePadding sx={styles.listItemBlock}>
            <ListItemButton onClick={handleProfileButtonClick} sx={profileButton(theme, open)}>
              {!open ? (
                <ListItemIcon onClick={() => toggleDrawer(!open)} sx={profileListItemIcon(open)}>
                  {userProfile?.profile_image ? (
                    <AvatarWithConditionalCrossOrigin
                      styles={styles.listItemAvatar}
                      imageUrl={userProfile?.profile_image}
                      altText={userProfile?.full_name}
                    />
                  ) : (
                    <Avatar sx={styles.listItemAvatarIcon} src={defaultUserImage} alt={userProfile?.full_name} />
                  )}
                </ListItemIcon>
              ) : (
                <>
                  <ListItemIcon sx={profileListItemIcon(open)}>
                    {userProfile?.profile_image ? (
                      <AvatarWithConditionalCrossOrigin
                        styles={styles.listItemAvatar}
                        imageUrl={userProfile?.profile_image}
                        altText={userProfile?.full_name}
                      />
                    ) : (
                      <Avatar sx={styles.listItemAvatarIcon} src={defaultUserImage} alt={userProfile?.full_name} />
                    )}
                  </ListItemIcon>
                  <div style={styles.profileMenuButtonTextContainer}>
                    <span style={styles.profileMenuButtonText}>
                      {userProfile?.full_name?.length ? userProfile?.full_name : user?.email}
                    </span>
                  </div>

                  <KeyboardArrowDown sx={keyboardIcon(openBottom)} />
                </>
              )}
            </ListItemButton>
            {openBottom && (
              <List sx={styles.bottomMenuList}>
                {roleData.canView(SIDEBAR_SUB_MODULE_MAP.FAVORITE) ? (
                  <>
                    {BOTTOM_BAR_ITEMS.map((item: any) => {
                      const allowView = roleData.handleMenuData(item);
                      return (
                        allowView && (
                          <ListItem key={item.id} disablePadding sx={styles.listItemBlock}>
                            <NavLink style={({ isActive }) => mainLinkItem(open, theme, isActive)} to={item?.route}>
                              <ListItemButton sx={styles.profileLinkItem}>
                                <div style={open ? styles.openListIcon : styles.closedListIcon}>{item.icon}</div>
                                <span style={listItemText(open)}>{item.name}</span>
                              </ListItemButton>
                            </NavLink>
                          </ListItem>
                        )
                      );
                    })}
                    {roleData.canView(SIDEBAR_SUB_MODULE_MAP.FAVORITE) && (
                      <Tooltip title="Click to request this feature" open={!validTier && hoverStateFavorites}>
                        <ListItem
                          key="favorite"
                          disablePadding
                          sx={validTier ? styles.listItemBlock : styles.listItemDisabled}
                        >
                          <ListItemButton
                            onMouseOver={!validTier ? handleMouseOverFavorites : undefined}
                            onMouseOut={!validTier ? handleMouseOutFavorites : undefined}
                            onClick={validTier ? handleOpenFav : handleOpenPaidDialog}
                            sx={validTier ? styles.profileLinkButton : styles.profileLinkItemDisabled}
                          >
                            <div style={open ? styles.openListIcon : styles.closedListIcon}>
                              <StarBorderPurple500OutlinedIcon />
                            </div>
                            <span style={listItemText(open)}>Favorite</span>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {roleData.canView(SIDEBAR_SUB_MODULE_MAP.DASHBOARD_COLOR) && (
                      <Tooltip title="Click to request this feature" open={!validTier && hoverStateDashboardColor}>
                        <ListItem
                          key="dashboardColor"
                          disablePadding
                          sx={validTier ? styles.listItemBlock : styles.listItemDisabled}
                        >
                          <ListItemButton
                            onMouseOver={!validTier ? handleMouseOverDashboardColor : undefined}
                            onMouseOut={!validTier ? handleMouseOutDashboardColor : undefined}
                            onClick={validTier ? handleOpenColor : handleOpenPaidDialog}
                            sx={validTier ? styles.profileLinkButton : styles.profileLinkItemDisabled}
                          >
                            <div style={styles.openListIcon}>
                              <img
                                width="24"
                                height="24"
                                alt="Dashboard Color"
                                src={ChangeDashboardColorIcon}
                                style={styles.dashboardColorIcon}
                              />
                            </div>
                            <span style={listItemText(open)}>Dashboard Color</span>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    <ListItem key="logout" disablePadding sx={styles.listItemBlock}>
                      <ListItemButton onClick={handleLogout} sx={styles.profileLinkButton}>
                        <div style={open ? styles.openListIcon : styles.closedListIcon}>
                          <LogoutOutlinedIcon />
                        </div>
                        <span style={listItemText(open)}>Logout</span>
                      </ListItemButton>
                    </ListItem>
                  </>
                ) : (
                  <ListItem key="logout" disablePadding sx={styles.listItemBlock}>
                    <ListItemButton onClick={handleLogout} sx={styles.profileLinkButton}>
                      <div style={open ? styles.openListIcon : styles.closedListIcon}>
                        <LogoutOutlinedIcon />
                      </div>
                      <span style={listItemText(open)}>Logout</span>
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            )}
          </ListItem>
        </List>
        {open && (
          <Box sx={styles.copyrightBox}>
            <Typography sx={styles.copyrightText}>{COPYRIGHT_YEAR_TEXT}</Typography>
            <Typography sx={styles.copyrightText}>{COPYRIGHT_TEXT}</Typography>
          </Box>
        )}
      </Drawer>
      <IconButton sx={chevronButton(open)} onClick={() => toggleDrawer(!open)}>
        {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>

      {children}
    </Box>
  );
}
