import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FleetDevice, getFleetDeviceList } from 'src/api/fleet_device';
import {
  UpdateDeviceData,
  DeviceInfo,
  getAll,
  getAllByAccountId,
  updatedevice,
  checkoutOne,
  checkinOne,
  getAllByAccountIdNoPage,
  getAllByListAccountIdNoPage,
  Assets,
  deleteAsset,
  getAllDeviceDetails,
  exportAllByAccountId,
  getAllDevices,
  getAssetByAccount,
  allImportsseach,
  NotificationImports,
  updateStatus,
  getAllLonerDetails,
  getAllDeviceLonerDetails,
  getAggregatedData,
  aggregateUpdateOne,
  getAssetsByAccount,
} from '../api/device/device';
import { setLoading } from './commonSlice';
import { isAPIResponseFailure, RecordType } from '../api/base';
import appLogger from '../infrastructure/config/appLogger';
import type { RootState } from '../store';
import type { TokenParams } from '../api/base';

type InitialState = {
  assets: Assets[];
  assetsByAccount: Assets[];
  totalPagesNotification: number;
  devices: DeviceInfo[];
  listDevices: DeviceInfo[];
  exportDevices: DeviceInfo[];
  exportNotLonerDevices: DeviceInfo[];
  exportInLonerDevices: DeviceInfo[];
  exportDevicesNotLoaner: DeviceInfo[];
  exportDevicesInLoaner: DeviceInfo[];
  accountDevices: FleetDevice[];
  aggregatedDevices: DeviceInfo[];
  selectedItem: null;
  page: number;
  perPage: number;
  totalCount: number;
  totalPages: number;
  totalAccountDeviceCount: number;
  totalAccountDevicePages: number;
  totalAggregatedPages: number;
  listPageTotal: number;
  listCurrentPage: number;
  allDevices: any[];
  newNotification: NotificationImports[];
  totalNotLonerDevices: number;
  totalInLonerDevices: number;
  assetsDataLoading: boolean;
  assetsData: Assets[];
};

type FetchWithToken = {
  accountId: number | string;
  page?: number | string;
  token?: string;
  status?: string;
};

const sliceName = 'devices';
const initialState: InitialState = {
  assets: [],
  assetsByAccount: [],
  devices: [],
  listDevices: [],
  exportDevices: [],
  exportInLonerDevices: [],
  exportNotLonerDevices: [],
  exportDevicesNotLoaner: [],
  exportDevicesInLoaner: [],
  accountDevices: [],
  aggregatedDevices: [],
  selectedItem: null,
  page: 1,
  perPage: 10,
  totalCount: 0,
  totalPages: 0,
  totalAccountDeviceCount: 0,
  totalAccountDevicePages: 0,
  totalAggregatedPages: 0,
  allDevices: [],
  totalPagesNotification: 0,
  newNotification: [],
  totalNotLonerDevices: 0,
  totalInLonerDevices: 0,
  assetsDataLoading: false,
  assetsData: [],
  listCurrentPage: 0,
  listPageTotal: 0,
};

type FetchParamTypesWithPage = {
  token: string;
  page?: number;
  search_field?: string;
  search_value?: number | string;
  sort?: string;
  contains?: string;
  status: string;
  accountId: string | number;
  per_page?: number;
};
type Imports = {
  token: string;
  page?: number;
  search_field?: string;
  search_value?: number | string;
};

type UpdateImports = {
  token: string;
  data?: any;
};

export const fetchAllDevicesByAccount = createAsyncThunk<DeviceInfo | any, FetchParamTypesWithPage>(
  `${sliceName}/fetchById`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchById`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getAllByAccountId(params.accountId, params.token, params.status, params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllFleetDevices = createAsyncThunk<DeviceInfo | any, FetchParamTypesWithPage>(
  `${sliceName}/fetch-all-fleet-devices`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch-all-fleet-devices`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getFleetDeviceList(Number(params.accountId), params, params.token);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllDevices = createAsyncThunk<DeviceInfo | any, { token: string }>(
  `${sliceName}/fetchByAll`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchByAll`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getAllDevices(params.token);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const exportAllDevicesByAccount = createAsyncThunk<DeviceInfo | any, FetchParamTypesWithPage>(
  `${sliceName}/exportById`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/exportById`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await exportAllByAccountId(params.accountId, params.token, params.status, params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const exportNotLonerByAccount = createAsyncThunk<DeviceInfo | any, FetchParamTypesWithPage>(
  `${sliceName}/exportNotLonerByAccount`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/exportNotLonerByAccount`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getAllLonerDetails(params.accountId, params.token, params.status, 'Checked Out', params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);
export const exportDeviceNotLonerByAccount = createAsyncThunk<DeviceInfo | any, FetchParamTypesWithPage>(
  `${sliceName}/exportDeviceNotLonerByAccount`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/exportDeviceNotLonerByAccount`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getAllDeviceLonerDetails(
        params.accountId,
        params.token,
        params.status,
        'Checked_Out',
        params
      );
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const exportInLonerByAccount = createAsyncThunk<DeviceInfo | any, FetchParamTypesWithPage>(
  `${sliceName}/exportInLonerByAccount`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/exportInLonerByAccount`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getAllLonerDetails(
        params.accountId,
        params.token,
        params.status,
        'In Loaner Pool',
        params
      );
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);
export const exportDevicesInLonerByAccount = createAsyncThunk<DeviceInfo | any, FetchParamTypesWithPage>(
  `${sliceName}/exportDevicesInLonerByAccount`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/exportDevicesInLonerByAccount`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getAllDeviceLonerDetails(
        params.accountId,
        params.token,
        params.status,
        'In_Loaner_Pool',
        params
      );
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);
type FetchWithAssetToken = {
  pageNo?: number | string;
  searchField?: number | string;
  searchValue?: number | string;
  contains?: string;
  token?: any;
  account: any;
};

type FetchWithAccountToken = {
  accountId: string | number;
  token: string;
};

export const fetchAllAssetsByAccount = createAsyncThunk<Assets | any, FetchWithAssetToken>(
  `${sliceName}/fetchByAssets`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/fetchByAssets`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getAssetByAccount(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchAllDevicesByAccountNoPage = createAsyncThunk<DeviceInfo | any, FetchWithToken>(
  `${sliceName}/fetchByIdNoPage`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/fetchByIdNoPage`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      if (params.accountId !== null) {
        const response = await getAllByAccountIdNoPage(params);
        if (isAPIResponseFailure(response)) {
          return thunkAPI.rejectWithValue(response);
        }
        return response.data;
      }
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const listAllDevicesByAccountNoPage = createAsyncThunk<
  DeviceInfo | any,
  FetchWithToken & {
    params?: { [key: string]: any };
  }
>(`${sliceName}/listAllDevicesByAccountNoPage`, async (params, thunkAPI) => {
  const loaderName = `${sliceName}/listAllDevicesByAccountNoPage`;
  thunkAPI.dispatch(setLoading([loaderName, true]));
  try {
    if (params.accountId !== null) {
      const response = await getAllByListAccountIdNoPage(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    }
  } catch (e) {
    appLogger.error(e);
  } finally {
    thunkAPI.dispatch(setLoading([loaderName, false]));
  }
  return null;
});

type DeleteType = {
  recordId?: string | number;
  token: string;
};

export const deleteAssets = createAsyncThunk<RecordType | any, DeleteType>(
  `${sliceName}/delete`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/delete`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await deleteAsset(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
      throw e;
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
  }
);

export const fetchBuildingItems = createAsyncThunk<DeviceInfo[], TokenParams, { state: RootState }>(
  `${sliceName}/fetchBuilding`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      // @TODO - Change this later
      const todoList = await getAll(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAggregatedData = createAsyncThunk<any, any, { state: RootState }>(
  `${sliceName}/fetchAggregatedData`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchAggregatedData`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await getAggregatedData(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllDeviceItems = createAsyncThunk<DeviceInfo[], TokenParams, { state: RootState }>(
  `${sliceName}/fetchBuilding`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      // @TODO - Change this later
      const todoList = await getAllDeviceDetails(params);
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const updateLoanerDevice = createAsyncThunk<RecordType | null, UpdateDeviceData>(
  `${sliceName}/updateLoanerDevice`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/update`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await updatedevice(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const updateAggregateLoanerDevice = createAsyncThunk<RecordType | null, UpdateDeviceData>(
  `${sliceName}/updateAggregateLoanerDevice`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/updateAggregateLoanerDevice`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await aggregateUpdateOne(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const updateCheckOutDevice = createAsyncThunk<RecordType | null, UpdateDeviceData>(
  `${sliceName}/updateCheckOut`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/update`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await checkoutOne(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const updateCheckInDevice = createAsyncThunk<RecordType | null, UpdateDeviceData>(
  `${sliceName}/updateCheckIn`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/update`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await checkinOne(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchImportInDevice = createAsyncThunk<DeviceInfo[], Imports>(
  `${sliceName}/fetchImportInDevice`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/update`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await allImportsseach(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const updateNotification = createAsyncThunk<UpdateImports | null, any>(
  `${sliceName}/updateCheckIn`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/update`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await updateStatus(params.data, params.token);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchAssetsByAccount = createAsyncThunk<Assets | any, FetchWithAccountToken>(
  `${sliceName}/fetchAssetsByAccount`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/fetchAssetsByAccount`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getAssetsByAccount(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);
const deviceSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    changeLoanerStatus: (state, payload: PayloadAction<{ serialNumber: string; status: boolean }>) => {
      const index = state.devices.findIndex((item) => payload.payload.serialNumber === item.serial_number);
      state.devices[index].loaner_status = payload.payload.status ? 'Checked Out' : 'In Loaner Pool';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBuildingItems.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload[0], 'total', 0);
      state.devices = get(payload, 'rows', []);
      state.totalCount = totalCount;
      state.totalPages = Math.ceil(totalCount / state.perPage);
    });
    builder.addCase(fetchAllDevicesByAccount.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.accountDevices = get(payload, 'rows', []);
      state.totalAccountDeviceCount = totalCount;
      state.devices = get(payload, 'rows', []);
      state.totalAccountDevicePages = Math.ceil(totalCount / state.perPage);
    });
    builder.addCase(exportAllDevicesByAccount.fulfilled, (state, action) => {
      const { payload } = action;
      state.exportDevices = get(payload, 'rows', []);
    });

    builder.addCase(exportNotLonerByAccount.fulfilled, (state, action) => {
      const { payload } = action;
      state.exportNotLonerDevices = get(payload, 'rows', []);
      state.totalNotLonerDevices = Math.ceil(get(payload, 'total', 0) / state.perPage);
    });
    builder.addCase(exportInLonerByAccount.fulfilled, (state, action) => {
      const { payload } = action;
      state.exportInLonerDevices = get(payload, 'rows', []);
      state.totalInLonerDevices = Math.ceil(get(payload, 'total', 0) / state.perPage);
    });
    builder.addCase(exportDeviceNotLonerByAccount.fulfilled, (state, action) => {
      state.exportDevicesNotLoaner = action.payload;
    });
    builder.addCase(exportDevicesInLonerByAccount.fulfilled, (state, action) => {
      state.exportDevicesInLoaner = action.payload;
    });
    builder.addCase(fetchAllDevicesByAccountNoPage.fulfilled, (state, action) => {
      const { payload } = action;
      state.devices = get(payload, 'rows', []);
    });
    builder.addCase(listAllDevicesByAccountNoPage.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = parseInt(get(payload, 'total', '0'), 10) || 0;
      const currentRows = get(payload, 'rows', []);
      const currentArgs = (get(action, 'meta.arg.params.search_value') || '').split(':').length;
      const isSelectedValue = currentArgs === 3 && isEmpty(currentRows);
      if (!isSelectedValue) {
        state.listDevices = currentRows;
        state.listPageTotal = totalCount;
        state.listCurrentPage = Math.ceil(totalCount / 50);
      }
    });
    builder.addCase(fetchAllDevices.fulfilled, (state, action) => {
      const { payload } = action;
      state.allDevices = payload;
    });
    builder.addCase(fetchAllAssetsByAccount.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.totalCount = totalCount;
      state.totalPages = Math.ceil(totalCount / state.perPage);
      state.assetsByAccount = get(payload, 'rows', []);
    });
    builder.addCase(fetchImportInDevice.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.totalPagesNotification = Math.ceil(totalCount / state.perPage);
      state.newNotification = get(payload, 'rows', []);
    });
    builder.addCase(fetchAggregatedData.pending, (state) => {
      state.totalAggregatedPages = 0;
      state.aggregatedDevices = [];
    });
    builder.addCase(fetchAggregatedData.fulfilled, (state, action) => {
      const { payload } = action;
      const data = get(payload, 'rows') || [];
      const totalCount = data.length;
      state.totalAggregatedPages = Math.ceil(totalCount / state.perPage);
      state.aggregatedDevices = data;
    });
    builder.addCase(fetchAssetsByAccount.pending, (state) => {
      state.assetsDataLoading = true;
    });
    builder.addCase(fetchAssetsByAccount.fulfilled, (state, action) => {
      state.assetsData = action.payload;
      state.assetsDataLoading = false;
    });
    builder.addCase(fetchAssetsByAccount.rejected, (state) => {
      state.assetsDataLoading = false;
    });
    builder.addCase(fetchAllFleetDevices.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'totalRecords', 0);
      state.accountDevices = get(payload, 'records', []);
      state.totalAccountDeviceCount = totalCount;
      state.devices = get(payload, 'records', []);
      state.totalAccountDevicePages = Math.ceil(totalCount / state.perPage);
    });
  },
});

export default deviceSlice.reducer;

export const { changeLoanerStatus } = deviceSlice.actions;

export const devicesStateItem = createSelector(
  (state: RootState) => state.device.devices,
  (items: DeviceInfo[]) => items
);

export const devicesListStateItem = createSelector(
  (state: RootState) => state.device.listDevices,
  (items: DeviceInfo[]) => items
);

export const devicesAccountStateItem = createSelector(
  (state: RootState) => state.device,
  (items: InitialState) => items
);

export const getAggregatedStateItem = createSelector(
  (state: RootState) => state.device,
  (items: InitialState) => ({
    devices: items.aggregatedDevices,
    pages: items.totalAggregatedPages,
    perPage: items.perPage,
  })
);

export const selectAssetsData = createSelector(
  (state: RootState) => state.device.assetsData,
  (assetsData) => assetsData
);

export const selectAssetsDataLoading = createSelector(
  (state: RootState) => state.device.assetsDataLoading,
  (loading) => loading
);
