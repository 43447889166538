import get from 'lodash/get';
import baseAPIFactory, { handleTokenHeaders } from './base';
import { FetchParamTypesWithPage } from './account/account';

export interface userRoleDetails {
  record_id: number;
  role_description: string | null;
  role_name: string | null;
}

const END_POINTS = {
  FETCH_ALL: () => '/external_user_role/all',
  FETCH_FACILITATOR: (accountNumber: string | number) => `external_user_role/get_all_facilitators/${accountNumber}`,
  FETCH_STUDENT_TECH: (accountNumber: string | number) => `external_user_role/get_all_student_tech/${accountNumber}`,
  GET_ONE_BY_NAME: (accountNumber: string | number, fullName: string) =>
    `student/getByName/${fullName}/${accountNumber}`,
};
export const getStudentByName = async ({
  accountNumber,
  fullName,
  token,
}: {
  accountNumber: string | number;
  fullName: string;
  token: string;
}) => baseAPIFactory.get<any[]>(END_POINTS.GET_ONE_BY_NAME(accountNumber, fullName), handleTokenHeaders({ token }));
export const getAllRoles = async (props: FetchParamTypesWithPage) => {
  const params: { [key: string]: any } = {};
  const pageNo = parseInt(get(props, 'page', '0') as any, 10);
  if (pageNo > 0) {
    params.per_page = pageNo;
  }
  if (props.search_field && props.search_value) {
    params.search_field = props.search_field;
    params.search_value = props.search_value;
  }
  return baseAPIFactory.get<userRoleDetails[]>(`${END_POINTS.FETCH_ALL()}`, {
    params,
    ...handleTokenHeaders(props),
  });
};
export const getFacilitator = async ({
  accountNumber,
  token,
  extraParams = null,
}: {
  accountNumber: string | number;
  extraParams?: any;
  token: string;
}) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<any>(END_POINTS.FETCH_FACILITATOR(accountNumber), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};
export const getStudentTech = async ({
  accountNumber,
  token,
  extraParams = null,
}: {
  accountNumber: string | number;
  extraParams?: any;
  token: string;
}) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<any>(END_POINTS.FETCH_STUDENT_TECH(accountNumber), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};
