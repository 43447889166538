import baseAPIFactory, { handleTokenHeaders } from './base';

export type FetchParamTypes = {
  token?: string;
};

export type FetchParamTypesWithPage = {
  token?: string;
};

export type ItemList = {
  record_id: number;
  issue: string;
};

export interface repairInhouseTableData {
  record_id: number;
  full_name: string;
  title: string;
  email: string;
  external_user_role: string;
  phone: string;
  cell_number: string;
  user_status: string;
  in_house_repair: boolean;
}

export interface FetchrepairInhouseParamTypes {
  accountId: number;
  token?: string;
}

const END_POINTS = {
  FETCH_ALL: () => `/repair_description/`,
  FETCH_ALL_INHOUSETICKETS: (accountNumber: number | undefined) => `/repair_ticket/${accountNumber}/inhouseTickets`,
  FETCH_ALL_INHOUSE_REPAIR_TICKETS: (accountNumber: number | undefined) =>
    `/repair_ticket/${accountNumber}/inHouseRepairTickets`,
};

export const getPaginatedList = async (token: string) =>
  baseAPIFactory.get<ItemList[]>(END_POINTS.FETCH_ALL(), handleTokenHeaders({ token }));

export const getAllInhouseTicketsList = async ({
  accountId,
  token,
}: {
  accountId: number | undefined;
  token?: string;
}) => baseAPIFactory.get<ItemList[]>(END_POINTS.FETCH_ALL_INHOUSETICKETS(accountId), handleTokenHeaders({ token }));

export const getAllInHouseRepairTickets = async ({
  accountId,
  token,
}: {
  accountId: number | undefined;
  token?: string;
}) =>
  baseAPIFactory.get<ItemList[]>(END_POINTS.FETCH_ALL_INHOUSE_REPAIR_TICKETS(accountId), handleTokenHeaders({ token }));
