import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { setLoading } from './commonSlice';
import appLogger from '../infrastructure/config/appLogger';
import { isAPIResponseFailure, RecordType } from '../api/base';
import type { RootState } from '../store';
import {
  AdminApprove,
  AdminDeny,
  createNewUser,
  denyOne,
  fetchOneUser,
  getAllUsers,
  getAllUsersAdmin,
  getBulkUser,
  getOneUser,
  getPaidUsers,
  updateAdminApprove,
  updateOneUser,
  userBulk,
  UserCreateInitialType,
  userDetails,
  userPaid,
  createExternalUser,
  deleteExternalUser as deleteOneExternalUser,
} from '../api/externalUser';
import { getAllRoles, userRoleDetails } from '../api/externalUserRole';
import type { FetchParamTypesWithPage } from '../api/account/account';

const sliceName = 'externalUser';

export interface InitialState {
  perPage: number;
  loggedInUser: any;
  accountUsers: userBulk[];
  roles: userRoleDetails[];
  user: userDetails[];
  userPaid: userPaid[];
  totalRolesCount: number;
  totalRolesPages: number;
  totalAccountUserCount: number;
  totalAccountUserPages: number;
  accountUsersAdmin: userBulk[];
  totalAccountUserCountAdmin: number;
  totalAccountUserPagesAdmin: number;
}

const initialState: InitialState = {
  perPage: 0,
  loggedInUser: null,
  accountUsers: [],
  roles: [],
  user: [],
  userPaid: [],
  totalRolesCount: 0,
  totalRolesPages: 0,
  totalAccountUserCount: 0,
  totalAccountUserPages: 0,
  accountUsersAdmin: [],
  totalAccountUserCountAdmin: 0,
  totalAccountUserPagesAdmin: 0,
};

type fetchUserWithToken = {
  userEmail?: string;
  token?: string;
};

type fetchBulkUserWithToken = {
  userEmail?: string;
  token?: string;
  extraParams?: any;
};

type fetchBulkUserWithTokenAdmin = {
  token: string;
  extraParams?: any;
};

type FetchBulkUserInformationWithToken = {
  recordId: string | number;
  token: string;
};

type DeleteExternalUserInput = {
  recordId: number;
  token: string;
};

export const fetchLoggedInUser = createAsyncThunk<userDetails[], fetchUserWithToken>(
  `${sliceName}/fetchLoggedInUser`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const loggedInUser = await getOneUser(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(loggedInUser)) {
        return ThunkAPI.rejectWithValue(loggedInUser);
      }
      return loggedInUser.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchBulkUser = createAsyncThunk<userBulk[], fetchBulkUserWithToken>(
  `${sliceName}/fetchBulkUsers`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const loggedInUser: any = await getBulkUser(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(loggedInUser)) {
        return ThunkAPI.rejectWithValue(loggedInUser);
      }
      return loggedInUser.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchBulkUserAdmin = createAsyncThunk<userBulk[], fetchBulkUserWithTokenAdmin>(
  `${sliceName}/fetchBulkUsersAdmin`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const loggedInUser: any = await getAllUsersAdmin(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(loggedInUser)) {
        return ThunkAPI.rejectWithValue(loggedInUser);
      }
      return loggedInUser.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchUserAndUpdateState = createAsyncThunk<userBulk, FetchBulkUserInformationWithToken>(
  `${sliceName}/fetchAndUpdateState`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchAndUpdateState`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const loggedInUser: any = await fetchOneUser(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(loggedInUser)) {
        return ThunkAPI.rejectWithValue(loggedInUser);
      }
      return loggedInUser.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

type fetchUsersWithToken = {
  token: string;
  page?: number;
};

export const fetchAllUsers = createAsyncThunk<userDetails[], fetchUsersWithToken>(
  `${sliceName}/AllUsers`,
  async (params, ThunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/AllUsers`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getAllUsers(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

type fetchPaidUsersWithToken = {
  token: string;
};

export const fetchPaidUsers = createAsyncThunk<userPaid[], fetchPaidUsersWithToken>(
  `${sliceName}/PaidUsers`,
  async (params, ThunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/PaidUsers`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getPaidUsers(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllRoles = createAsyncThunk<userRoleDetails[], FetchParamTypesWithPage>(
  `${sliceName}/fetchUserRole`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchUserRole`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const allRoles = await getAllRoles(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(allRoles)) {
        return ThunkAPI.rejectWithValue(allRoles);
      }
      return allRoles.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const deleteExternalUser = createAsyncThunk<RecordType | any, DeleteExternalUserInput>(
  `${sliceName}/delete`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/delete`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await deleteOneExternalUser(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
      throw e;
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
  }
);

export const createNewExternalUser = createAsyncThunk<RecordType | null, { userData: any; token: string }>(
  `${sliceName}/create-external-user`,
  async ({ userData, token }, thunkAPI) => {
    appLogger.log(userData);
    const loaderName = `${sliceName}/fetch`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await createExternalUser(userData, { token });

      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const postNewUser = createAsyncThunk<RecordType | null, UserCreateInitialType>(
  `${sliceName}/create`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/fetch`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await createNewUser(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const updateAdminApproveItems = createAsyncThunk<RecordType | null, AdminApprove>(
  `${sliceName}/updateAdmin`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/updateAdmin`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await updateAdminApprove(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const updateUser = createAsyncThunk<RecordType | null, { userData: any; token: string }>(
  'externalUserSlice/update',
  async ({ userData, token }, thunkAPI) => {
    const loaderName = 'externalUserSlice/fetch';
    thunkAPI.dispatch(setLoading([loaderName, true])); // Indicate the loading state
    const { record_id: userId, ...updateData } = userData;
    try {
      const response = await updateOneUser(userId, updateData, { token });

      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }

      return response.data; // Assuming the response has a 'data' property with the relevant data
    } catch (e) {
      appLogger.error(e);
      return thunkAPI.rejectWithValue(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false])); // Reset the loading state
    }
  }
);

export const denyOneReq = createAsyncThunk<RecordType | any, AdminDeny>(
  `${sliceName}/deny`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/deny`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await denyOne(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
      throw e;
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
  }
);

export const externalUserSlice = createSlice({
  name: sliceName,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateAccountUsers: (state, action) => {
      state.accountUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const { payload } = action;
      state.loggedInUser = payload;
    });
    builder.addCase(fetchLoggedInUser.fulfilled, (state, action) => {
      const { payload } = action;
      state.loggedInUser = payload;
    });
    builder.addCase(fetchBulkUser.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.accountUsers = get(payload, 'rows', []);
      state.totalAccountUserCount = totalCount;
      state.totalAccountUserPages = Math.ceil(totalCount / 10);
    });
    builder.addCase(fetchBulkUserAdmin.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.accountUsersAdmin = get(payload, 'rows', []);
      state.totalAccountUserCountAdmin = totalCount;
      state.totalAccountUserPagesAdmin = Math.ceil(totalCount / 10);
    });
    builder.addCase(fetchAllRoles.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.roles = get(payload, 'rows', []);
      state.totalRolesCount = totalCount;
      state.totalRolesPages = Math.ceil(totalCount / state.perPage);
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.user = get(payload, 'rows', []);
      state.totalAccountUserCount = totalCount;
      state.totalAccountUserPages = Math.ceil(totalCount / state.perPage);
    });
    builder.addCase(fetchPaidUsers.fulfilled, (state, action) => {
      const { payload } = action;
      state.userPaid = payload;
    });
    builder.addCase(fetchUserAndUpdateState.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.accountUsers = get(payload, 'rows', []);
      state.totalAccountUserCount = totalCount;
      state.totalAccountUserPages = Math.ceil(totalCount / state.perPage);
    });
  },
});

export default externalUserSlice.reducer;
export const { updateAccountUsers } = externalUserSlice.actions;

export const externalUserStateItem = createSelector(
  (state: RootState) => state.externalUser,
  (item: InitialState) => item
);

export const externalUserLoggedInUser = createSelector(
  (state: RootState) => get(state, 'externalUser.loggedInUser', null),
  (item: userDetails[]) => item
);

export const externalUserBulkItems = createSelector(
  (state: RootState) => state.externalUser.accountUsers,
  (item: userBulk[]) => item
);
