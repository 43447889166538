import baseAPIFactory, { handleTokenHeaders } from './base';

const END_POINTS = {
  FETCH_ALL: () => `account/building/`,
  FETCH_ALL_BULDING_BYACCOUNT: () => 'account/building/all/',
  VALIDATE_UPS_ADDRESS: () => 'account/building/validateUPSAddress/',
  FETCH_ALL_NO_PAGE: (email: string | undefined) => `account/building/get_all/${email}`,
  FETCH_ALL_OFFICE_SITES: () => 'officeSite/all',
  GET_ITEM: (itemId: string | number) => `account/building/${itemId}`,
  CREATE_BUILDING: () => `account/building/`,
  FETCH_COLOR: (itemId: string | number) => `account/building/color/${itemId}`,
  CREATE_FAV: () => 'user_favorite/',
  FETCH_FAV: (userId?: number | string) => `user_favorite/${userId}`,
  DELETE_FAV: (recordId?: string | number) => `user_favorite/${recordId}`,
  UPDATE_BUILDING: (itemId: string | number | null) => `account/building/${itemId}`,
  UPDATE_ACCOUNT_BUILDING: (itemId: string | number | null) => `/building/${itemId}`,
  FETCH_BUILDING_BY_EMAIL: (email: string) => `/account/building/email/${email}`,
  ACCOUNT_BUILDING: () => '/building/',
  BUILDING_IN_ACCOUNT: (itemId: string | number | null) => `/account/building/building_list/${itemId}`,
  ACCOUNT_CREDIT_UPDATE: (accountNumber: string | number | null) => `/account/${accountNumber}/account_credit`,
  POST_BULK_BUILDING: () => 'account/building/bulk',
  getBuildingData: (accountNumber: number | undefined) => `/building/${accountNumber}/allbuilding`,
};

export type FetchParamTypesWithPage = {
  page?: number;
  per_page?: number | string;
  token: string;
};

export type BuildingItem = {
  record_id: number;
  building_name: string;
  account: number;
  default_building: boolean;
  account_name: string;
  billing_address_address_1: string;
  billing_address_address_2: string;
  billing_address_city: string;
  billing_address_country: string;
  billing_address_record_id: number;
  billing_address_us_state: string;
  billing_address_zip: string;
  building_image: string;
  dashboard_color_primary: string;
  dashboard_color_secondary: string;
  extension: string;
  phone: string;
  shipping_address_address_1: string;
  shipping_address_address_2: string;
  shipping_address_city: string;
  shipping_address_country: string;
  shipping_address_record_id: number;
  shipping_address_us_state: string;
  shipping_address_zip: string;
};

export type OfficeSiteItem = {
  record_id: number;
  office_address: number;
  site_abbreviation: string;
  site_name: string;
  created_date: string;
};

export type BuildingColor = {
  dashboard_color_primary: string;
  dashboard_color_secondary: string;
  token?: string;
  itemId: string;
};

export type FavCreateInitialType = {
  title: string;
  widget_url: string;
  user_id: string | number | undefined;
  token?: string;
};

export type FavItemInitialType = {
  widget_url: string;
  user_id: string | undefined;
  title: string;
  token?: string;
};

export type FavoriteType = {
  record_id: number;
  title: string;
  widget_url: string;
};

export type DeleteType = {
  userId: string | number;
  webpage: number;
  token?: string;
};

export const fetchBuildingDetailsByEmailId = async ({
  emailAddress,
  token,
  page,
  searchField,
  searchValue,
  contains,
}: {
  emailAddress?: string | any;
  token?: string;
  page?: number;
  searchField?: string;
  searchValue?: string;
  contains?: string;
}) => {
  const params: { [key: string]: any } = {};
  if (page) {
    params.page_no = page;
  }
  if (searchValue) {
    params.search_value = searchValue;
  }
  if (searchField) {
    params.search_field = searchField;
  }
  if (contains) {
    params.contains = contains;
  }
  return baseAPIFactory.get<any[]>(END_POINTS.FETCH_BUILDING_BY_EMAIL(emailAddress), {
    params,
    ...handleTokenHeaders({ token }),
  });
};

export const getPaginatedList = async ({ token }: { token: string }) =>
  baseAPIFactory.get<BuildingItem[]>(END_POINTS.FETCH_ALL(), handleTokenHeaders({ token }));

export const getBuildingNoPage = async ({ email, token }: { email?: string; token?: string }) =>
  baseAPIFactory.get<any>(END_POINTS.FETCH_ALL_NO_PAGE(email), handleTokenHeaders({ token }));

export const getOfficeSites = async ({ token }: { token: string }) =>
  baseAPIFactory.get<OfficeSiteItem[]>(END_POINTS.FETCH_ALL_OFFICE_SITES(), handleTokenHeaders({ token }));

export const createBuilding = async (data: any, token: string) =>
  baseAPIFactory.post(END_POINTS.CREATE_BUILDING(), data, handleTokenHeaders({ token }));

export const fetchBuildingById = async ({ token, itemId }: { token: string; itemId: string | number }) =>
  baseAPIFactory.get<BuildingItem>(END_POINTS.GET_ITEM(itemId), handleTokenHeaders({ token }));

export const fetchBuildingByAccount = async ({ token, itemId }: { token: string; itemId: string | number }) =>
  baseAPIFactory.get<any>(END_POINTS.BUILDING_IN_ACCOUNT(itemId), handleTokenHeaders({ token }));

export const fetchColor = async ({ token, itemId }: { token: string; itemId: string | number }) =>
  baseAPIFactory.get(END_POINTS.FETCH_COLOR(itemId), handleTokenHeaders({ token }));

export const updateColor = async (data: BuildingColor) =>
  baseAPIFactory.put(
    END_POINTS.FETCH_COLOR(data.itemId),
    {
      dashboard_color_primary: data.dashboard_color_primary,
      dashboard_color_secondary: data.dashboard_color_secondary,
    },
    handleTokenHeaders(data)
  );

export const createFavView = async (data: FavCreateInitialType) =>
  baseAPIFactory.post(END_POINTS.CREATE_FAV(), data, handleTokenHeaders(data));

export const fetchFav = async ({ token, userId }: { token: string; userId?: number | string }) =>
  baseAPIFactory.get<FavoriteType[]>(END_POINTS.FETCH_FAV(userId), handleTokenHeaders({ token }));

export const deleteOne = async ({ token, recordId }: { token: string; recordId?: string | number }) =>
  baseAPIFactory.delete(END_POINTS.DELETE_FAV(recordId), handleTokenHeaders({ token }));

export const updateBuilding = async (recordId: string | number | null, data: any, token: any) =>
  baseAPIFactory.put(END_POINTS.UPDATE_BUILDING(recordId), data, handleTokenHeaders({ token }));

export const updateInAccountBuilding = async (recordId: string | number | null, data: any, token: any) => {
  baseAPIFactory.put(END_POINTS.UPDATE_ACCOUNT_BUILDING(recordId), data, handleTokenHeaders({ token }));
};
export const createAccountBuilding = async (data: any, token: string) =>
  baseAPIFactory.post(END_POINTS.ACCOUNT_BUILDING(), data, handleTokenHeaders({ token }));
export const updateCredit = async (accountNumber: string | number | null, data: any, token: any) => {
  baseAPIFactory.put(END_POINTS.ACCOUNT_CREDIT_UPDATE(accountNumber), data, handleTokenHeaders({ token }));
};

export const getAllbuildings = async (props: FetchParamTypesWithPage) => {
  const params: { [key: string]: any } = {};
  if (props.page) {
    params.page_no = props.page;
  }
  if (props.per_page) {
    params.per_page = props.per_page;
  }
  return baseAPIFactory.get<any[]>(END_POINTS.ACCOUNT_BUILDING(), {
    params,
    ...handleTokenHeaders(props),
  });
};

export const deleteInAccountBuilding = async ({ token, recordId }: { token: string; recordId: string | number }) =>
  baseAPIFactory.delete(END_POINTS.UPDATE_ACCOUNT_BUILDING(recordId), handleTokenHeaders({ token }));

export const getList = async ({ token }: { token: string }) =>
  baseAPIFactory.get<BuildingItem[]>(END_POINTS.FETCH_ALL_BULDING_BYACCOUNT(), handleTokenHeaders({ token }));

export const validateUPSAddress = async (data: any, token: string) =>
  baseAPIFactory.post(END_POINTS.VALIDATE_UPS_ADDRESS(), data, handleTokenHeaders({ token }));

export const createBulkBuilding = async (data: any, token: string) =>
  baseAPIFactory.post(END_POINTS.POST_BULK_BUILDING(), data, handleTokenHeaders({ token }));

export const getBuildingData = async ({ accountId, token }: { accountId: number | undefined; token?: string }) =>
  baseAPIFactory.get(END_POINTS.getBuildingData(accountId), handleTokenHeaders({ token }));
