import { isEmpty } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import useRoleIdentity from '../roles/hooks';
import { FORBIDDEN_ACCESS } from '../../constants/routes';

type Props = {
  name?: string | null;
  children: JSX.Element;
};

const ProtectedRoute = ({ name = null, children }: Props) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const identityCheck = useRoleIdentity(name);
  if (!isLoading && !isAuthenticated) {
    loginWithRedirect();
    return null;
  }
  if (!isEmpty(name) && isAuthenticated) {
    if (identityCheck.canViewPage()) {
      return children;
    }
    return <Navigate to={FORBIDDEN_ACCESS} />;
  }
  return children;
};

export default ProtectedRoute;
