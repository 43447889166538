import { get } from 'lodash';
import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from './commonSlice';
import { PER_PAGE_RECORD } from '../constants/data/roadmap.constants';
import appLogger from '../infrastructure/config/appLogger';
import {
  createRepairBox,
  createRepairBoxDetails,
  DeviceDetails,
  fecthDeviceByAccount,
  fecthRepairBuildingItem,
  getPaginatedList,
  TicketAccount,
  updateRepairTicket,
  UpdateTicket,
  TicketBuilding,
  fetchRepairAccountItem,
  RepairBox,
  UserData,
  getUser,
  TicketContent,
  fetchTicketsNoBox,
  NoBoxTickets,
  fetchWorkflow,
  WorkflowTicket,
  UpdateStatus,
  updateTicketStatus,
  LineItem,
  createRepairTicketLineItems,
  getRepairLineItems,
  updateInHouseRepairTicket,
  RepairTicket,
  fetchTicketsInBox,
  fetchTicketsInboundBox,
  RepairImages,
  fetchRepairImages,
  getTicketByTicketNumber,
  fetchRepairAccountItems,
  getRepairTicketsData,
  getRepairBoxesData,
  WorkflowData,
  getSRCUserData,
  getRepairTicketsDataByStudent,
  getTestRepairTicketsDataByStudent,
} from '../api/repair';
import { isAPIResponseFailure, RecordType } from '../api/base';
import type { RootState } from '../store';
import { getUsedOnParts, UsedParts, FailureType, getFailureType } from '../api/repairReport';
import { NoteItem, getRepairNotes } from '../api/notes';
import {
  FetchRepairParamTypes,
  RepairTicketsTableData,
  RepairBoxesTableData,
  RepairSRCFacilitatorsTableData,
  FetchSRCFacilitatorsParamTypes,
} from '../api/types/repair';
import {
  getTestLineItemsByTicketNumber,
  getTestRepairImagesByTicketNumber,
  listTestNewNoteItem,
} from '../api/testRepair';

const sliceName = 'repair';

export interface InitialState {
  page: number;
  perPage: number;
  totalCount: number;
  totalPages: number;
  repairBox: TicketContent[];
  repairBoxOutbound: any[];
  totalCountOutbound: number;
  repairTicket: TicketAccount[];
  selectedItem: null | TicketContent;
  buildingItem: any | TicketBuilding;
  deviceItem: any | DeviceDetails[];
  noBoxTickets: NoBoxTickets[];
  workflow: WorkflowData;
  boxTicket: RepairTicket[];
  repairImage: RepairImages[];
  user: any | UserData;
  LineItems: LineItem[];
  notes: NoteItem[];
  parts: any | UsedParts;
  workFlowCount: number;
  dataLoading: boolean;
  repairTicketsData: RepairTicketsTableData[] | null;
  repairTicketsDataLoading: boolean;
  repairBoxesData: RepairBoxesTableData[] | null;
  repairBoxesDataLoading: boolean;
  repairSRCFacilitatorsDataLoading: boolean;
  repairStudentsTicketsDataLoading: boolean;
  repairStudentsTicketsData: RepairTicketsTableData[] | null;
  repairSRCFacilitatorsData: RepairSRCFacilitatorsTableData[] | null;
  repairLoading: boolean;
}

const initialState: InitialState = {
  page: 1,
  perPage: PER_PAGE_RECORD,
  totalCount: 0,
  workFlowCount: 0,
  totalPages: 0,
  LineItems: [],
  boxTicket: [],
  repairBox: [],
  repairImage: [],
  noBoxTickets: [],
  repairTicket: [],
  notes: [],
  selectedItem: null,
  buildingItem: null,
  deviceItem: null,
  workflow: {},
  user: null,
  parts: null,
  repairBoxOutbound: [],
  totalCountOutbound: 0,
  dataLoading: false,
  repairTicketsData: [],
  repairTicketsDataLoading: false,
  repairBoxesData: [],
  repairBoxesDataLoading: false,
  repairSRCFacilitatorsDataLoading: false,
  repairSRCFacilitatorsData: [],
  repairLoading: false,
  repairStudentsTicketsDataLoading: false,
  repairStudentsTicketsData: [],
};

type fetchRepairBoxWithToken = {
  accountNum: number | string;
  boxType: string;
  offset: number;
  search: string;
  token?: string;
  extraParams?: any | null;
};

type fetchRepairNoBoxWithToken = {
  accountNum: number | string;
  token?: string;
  extraParams?: any | null;
};

type fetchRepairNoBoxWithTokens = {
  accountNum?: number;
  token?: string;
  extraParams?: any | null;
};

type fetchRepairTicketBuildingWithToken = {
  itemId?: number | string;
  token?: string;
};

type fetchRepairTicketAccountWithToken = {
  accountNum: number | string;
  repairType: string;
  token?: string;
  extraParams?: any | null;
};

type fetchRepairLineItemsWithToken = {
  repairId?: number | string;
  token?: string;
  isPracticeStudent?: boolean;
};

type fetchRepairNoteItemsWithToken = {
  ticketId?: number | string;
  token?: string;
  isPracticeStudent?: boolean;
};

type fetchDeviceWithToken = {
  accountId: string | number;
  token?: string;
};

type fetchUserWithToken = {
  token?: string;
};

type RepairBoxWithToken = RepairBox & {
  token: string;
};

type LineItemWithToken = LineItem & {
  token: string;
};

type TicketDetailsWithToken = {
  boxId?: string | number;
  token?: string;
};

export const fetchRepairItems = createAsyncThunk<TicketContent[], fetchRepairBoxWithToken>(
  `${sliceName}/fetch`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await getPaginatedList(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchRepairTicketsWithoutBox = createAsyncThunk<NoBoxTickets[], fetchRepairNoBoxWithTokens>(
  `${sliceName}/fetchNoBox`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await fetchTicketsNoBox(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchRepairLineItems = createAsyncThunk<LineItem[], fetchRepairLineItemsWithToken>(
  `${sliceName}/fetchLineItems`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchLineItems`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const isPracticeUser = !!get(params, 'isPracticeStudent', false);
      const toCall = isPracticeUser ? getTestLineItemsByTicketNumber : getRepairLineItems;
      const todoList = await toCall(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllRepairImages = createAsyncThunk<RepairImages[], fetchRepairLineItemsWithToken>(
  `${sliceName}/fetchLineImages`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchLineImages`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const isPracticeStudent = !!get(params, 'isPracticeStudent', false);
      const toCall = isPracticeStudent ? getTestRepairImagesByTicketNumber : fetchRepairImages;
      const todoList = await toCall(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchRepairTicketAccountItems = createAsyncThunk<TicketAccount | any, fetchRepairTicketAccountWithToken>(
  `${sliceName}/fetchByAccount`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchByAccount`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const TicketAccountItem = await fetchRepairAccountItem(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(TicketAccountItem)) {
        return ThunkAPI.rejectWithValue(TicketAccountItem);
      }
      return TicketAccountItem.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

type fetchTicketWithToken = {
  accountNum: string | number | null;
  repairType: string;
  token: string;
  page?: number;
  search_field?: string;
  search_value?: string;
};

export const fetchRepairTicketItems = createAsyncThunk<TicketAccount | any, fetchTicketWithToken>(
  `${sliceName}/fetchByPage`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchByPage`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const TicketAccountItem = await fetchRepairAccountItems(params.accountNum, params.repairType, params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(TicketAccountItem)) {
        return ThunkAPI.rejectWithValue(TicketAccountItem);
      }
      return TicketAccountItem.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchRepairWorkflowItems = createAsyncThunk<WorkflowTicket | any, fetchRepairNoBoxWithToken>(
  `${sliceName}/fetchWorkflow`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchWorkflow`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const TicketAccountItem = await fetchWorkflow(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(TicketAccountItem)) {
        return ThunkAPI.rejectWithValue(TicketAccountItem);
      }
      return TicketAccountItem.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllTicketsInBox = createAsyncThunk<RepairTicket | any, TicketDetailsWithToken>(
  `${sliceName}/getTicketDetails`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/getTicketDetails`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await fetchTicketsInBox(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchOneByTicketNumber = createAsyncThunk<RepairTicket | any, fetchRepairNoteItemsWithToken>(
  `${sliceName}/getTicketDetailsByNumber`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/getTicketDetailsByNumber`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getTicketByTicketNumber(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchAllTicketsInBoundBox = createAsyncThunk<RepairTicket | any, TicketDetailsWithToken>(
  `${sliceName}/getTicketDetailsInbound`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/getTicketDetailsInbound`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await fetchTicketsInboundBox(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchRepairTicketBuildingItems = createAsyncThunk<TicketAccount | any, fetchRepairTicketBuildingWithToken>(
  `${sliceName}/fetchByBuilding`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchByBuilding`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const TicketBuildingItem = await fecthRepairBuildingItem(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(TicketBuildingItem)) {
        return ThunkAPI.rejectWithValue(TicketBuildingItem);
      }
      return TicketBuildingItem.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchDeviceItems = createAsyncThunk<DeviceDetails | any, fetchDeviceWithToken>(
  `${sliceName}/fetchDevice`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchDevice`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const DeviceItem = await fecthDeviceByAccount(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(DeviceItem)) {
        return ThunkAPI.rejectWithValue(DeviceItem);
      }
      return DeviceItem.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const createRepairBoxItem = createAsyncThunk<RecordType | null, RepairBoxWithToken>(
  `${sliceName}/create`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const RepairBoxItem = await createRepairBox(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(RepairBoxItem)) {
        return ThunkAPI.rejectWithValue(RepairBoxItem);
      }
      return RepairBoxItem.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const createNewLineItem = createAsyncThunk<RecordType | null, LineItemWithToken>(
  `${sliceName}/createLineItem`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/createLineItem`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const TicketAccountItem = await createRepairTicketLineItems(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(TicketAccountItem)) {
        return ThunkAPI.rejectWithValue(TicketAccountItem);
      }
      return TicketAccountItem.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const repairBoxDetailsItems = createAsyncThunk<RecordType | null, TicketContent>(
  `${sliceName}/createByDetails`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchByDetails`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await createRepairBoxDetails(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchUser = createAsyncThunk<UserData | any, fetchUserWithToken>(
  `${sliceName}/fetchUser`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchUser`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getUser(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

type fetchUsedWithToken = {
  ticketNumber: string | number;
  token?: string;
};

type fetchFailureTypeWithToken = {
  token?: string;
};

export const fetchUsedParts = createAsyncThunk<UsedParts | any, fetchUsedWithToken>(
  `${sliceName}/fetchParts`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchParts`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getUsedOnParts(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const updateRepairTicketItems = createAsyncThunk<RecordType | null, UpdateTicket>(
  `${sliceName}/updateTicket`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/updateTicket`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await updateRepairTicket(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const updateInHouseRepairTicketItems = createAsyncThunk<RecordType | null, UpdateTicket>(
  `${sliceName}/updateTicket`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/updateTicket`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await updateInHouseRepairTicket(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const updateRepairTicketStatus = createAsyncThunk<RecordType | null, UpdateStatus>(
  `${sliceName}/updateTicketStatus`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/updateTicketStatus`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await updateTicketStatus(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const updateRepairNotes = createAsyncThunk<NoteItem[], fetchRepairNoteItemsWithToken>(
  `${sliceName}/updateTicketNotes`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/updateTicketNotes`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const isPracticeStudent = !!get(params, 'isPracticeStudent', false);
      const toCall = isPracticeStudent ? listTestNewNoteItem : getRepairNotes;
      const todoList = await toCall(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchFailureType = createAsyncThunk<FailureType | any, fetchFailureTypeWithToken>(
  `${sliceName}/fetchFailureType`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchFailureType`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getFailureType(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchRepairItemsOutbound = createAsyncThunk<FailureType | any, fetchRepairBoxWithToken>(
  `${sliceName}/fetchFailureType`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchFailureType`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await getPaginatedList(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchRepairTicketsData = createAsyncThunk<RepairTicketsTableData[], FetchRepairParamTypes>(
  `${sliceName}/fetchRepairTickets`,
  async (params, { rejectWithValue, dispatch }) => {
    const loaderName = `${sliceName}/fetchRepairTickets`;
    dispatch(setLoading([loaderName, true])); // Start loading
    try {
      const response = await getRepairTicketsData(params); // Make the API call

      if (isAPIResponseFailure(response)) {
        // Check for API failure
        return rejectWithValue(response); // Handle failure
      }

      return response.data; // Success
    } catch (error) {
      appLogger.error(error); // Log error
      return rejectWithValue(error); // Handle error
    } finally {
      dispatch(setLoading([loaderName, false])); // End loading
    }
  }
);

export const fetchRepairBoxesData = createAsyncThunk<RepairBoxesTableData[], FetchRepairParamTypes>(
  `${sliceName}/fetchRepairBoxes`,
  async (params, { rejectWithValue, dispatch }) => {
    const loaderName = `${sliceName}/fetchRepairBoxes`;
    dispatch(setLoading([loaderName, true])); // Start loading

    try {
      const response = await getRepairBoxesData(params); // Make the API call
      if (isAPIResponseFailure(response)) {
        // Check for API failure
        return rejectWithValue(response); // Handle failure
      }

      return response.data; // Success
    } catch (error) {
      appLogger.error(error); // Log error
      return rejectWithValue(error); // Handle error
    } finally {
      dispatch(setLoading([loaderName, false])); // End loading
    }
  }
);

export const fetchSRCFacilitatorsData = createAsyncThunk<
  RepairSRCFacilitatorsTableData[],
  FetchSRCFacilitatorsParamTypes
>(`${sliceName}/fetchSRCFacilitatorsData`, async (params, { rejectWithValue, dispatch }) => {
  const loaderName = `${sliceName}/fetchSRCFacilitatorsData`;
  dispatch(setLoading([loaderName, true])); // Start loading

  try {
    const response = await getSRCUserData(params); // Make the API call
    if (isAPIResponseFailure(response)) {
      // Check for API failure
      return rejectWithValue(response); // Handle failure
    }

    return response.data; // Success
  } catch (error) {
    appLogger.error(error); // Log error
    return rejectWithValue(error); // Handle error
  } finally {
    dispatch(setLoading([loaderName, false])); // End loading
  }
});

export const fetchStudentRepairTicketsData = createAsyncThunk<RepairTicketsTableData[], { token: string }>(
  `${sliceName}/fetchStudentRepairTicketsData`,
  async (params, { rejectWithValue, dispatch }) => {
    const loaderName = `${sliceName}/fetchStudentRepairTicketsData`;
    dispatch(setLoading([loaderName, true])); // Start loading

    try {
      const response = await getRepairTicketsDataByStudent(params); // Make the API call
      if (isAPIResponseFailure(response)) {
        // Check for API failure
        return rejectWithValue(response); // Handle failure
      }

      return response.data; // Success
    } catch (error) {
      appLogger.error(error); // Log error
      return rejectWithValue(error); // Handle error
    } finally {
      dispatch(setLoading([loaderName, false])); // End loading
    }
  }
);

export const fetchPracticeStudentRepairTicketsData = createAsyncThunk<RepairTicketsTableData[], { token: string }>(
  `${sliceName}/fetchPracticeStudentRepairTicketsData`,
  async (params, { rejectWithValue, dispatch }) => {
    const loaderName = `${sliceName}/fetchPracticeStudentRepairTicketsData`;
    dispatch(setLoading([loaderName, true])); // Start loading

    try {
      const response = await getTestRepairTicketsDataByStudent(params); // Make the API call
      if (isAPIResponseFailure(response)) {
        // Check for API failure
        return rejectWithValue(response); // Handle failure
      }

      return response.data; // Success
    } catch (error) {
      appLogger.error(error); // Log error
      return rejectWithValue(error); // Handle error
    } finally {
      dispatch(setLoading([loaderName, false])); // End loading
    }
  }
);
const repairSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetSelectedItem: (state) => {
      state.selectedItem = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRepairItems.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.repairBox = get(payload, 'rows', []);
      state.totalCount = totalCount;
    });
    builder.addCase(fetchRepairItemsOutbound.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.repairBoxOutbound = get(payload, 'rows', []);
      state.totalCountOutbound = totalCount;
    });

    builder.addCase(fetchRepairItems.pending, (state) => {
      state.selectedItem = null;
    });
    builder.addCase(fetchOneByTicketNumber.fulfilled, (state, action) => {
      state.repairTicket = action.payload;
    });
    builder.addCase(fetchRepairTicketAccountItems.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.repairTicket = get(payload, 'rows', []);
      state.totalCount = totalCount;
    });
    builder.addCase(fetchRepairWorkflowItems.fulfilled, (state, action) => {
      const { payload } = action;
      const pageSize = parseInt(get(action, 'meta.arg.extraParams.per_page') || '10', 10) || 10;
      const totalCount = parseInt(get(payload, 'totalRecords') || '0', 10) || 0;
      const workFlow = get(payload, 'result', {});
      state.workflow = workFlow;
      state.workFlowCount = Math.ceil(totalCount / pageSize);
    });
    builder.addCase(fetchRepairTicketBuildingItems.fulfilled, (state, action) => {
      state.buildingItem = action.payload;
    });
    builder.addCase(fetchAllTicketsInBox.fulfilled, (state, action) => {
      state.boxTicket = action.payload;
    });
    builder.addCase(fetchAllTicketsInBoundBox.fulfilled, (state, action) => {
      state.boxTicket = action.payload;
    });
    builder.addCase(fetchDeviceItems.fulfilled, (state, action) => {
      state.deviceItem = action.payload;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.user = get(payload, 'rows', []);
      state.totalCount = totalCount;
    });
    builder.addCase(fetchUsedParts.fulfilled, (state, action) => {
      state.parts = action.payload;
    });
    builder.addCase(fetchAllRepairImages.fulfilled, (state, action) => {
      state.repairImage = action.payload;
    });
    builder.addCase(fetchRepairTicketsWithoutBox.fulfilled, (state, action) => {
      state.noBoxTickets = action.payload;
    });
    builder.addCase(fetchRepairLineItems.fulfilled, (state, action) => {
      state.LineItems = action.payload;
    });
    builder.addCase(updateRepairNotes.fulfilled, (state, action) => {
      state.notes = action.payload;
    });
    builder.addCase(fetchRepairTicketsData.pending, (state) => {
      state.repairTicketsDataLoading = true;
    });
    builder.addCase(fetchRepairTicketsData.fulfilled, (state, action) => {
      state.repairTicketsData = action.payload;
      state.repairTicketsDataLoading = false;
    });
    builder.addCase(fetchRepairTicketsData.rejected, (state) => {
      state.repairTicketsDataLoading = false;
    });
    builder.addCase(fetchRepairBoxesData.pending, (state) => {
      state.repairBoxesDataLoading = true;
    });
    builder.addCase(fetchRepairBoxesData.fulfilled, (state, action) => {
      state.repairBoxesData = action.payload;
      state.repairBoxesDataLoading = false;
    });
    builder.addCase(fetchRepairBoxesData.rejected, (state) => {
      state.repairBoxesDataLoading = false;
    });
    builder.addCase(fetchSRCFacilitatorsData.pending, (state) => {
      state.repairSRCFacilitatorsDataLoading = true;
    });
    builder.addCase(fetchSRCFacilitatorsData.fulfilled, (state, action) => {
      state.repairSRCFacilitatorsData = action.payload;
      state.repairSRCFacilitatorsDataLoading = false;
    });
    builder.addCase(fetchSRCFacilitatorsData.rejected, (state) => {
      state.repairSRCFacilitatorsDataLoading = false;
    });
    builder.addCase(fetchStudentRepairTicketsData.pending, (state) => {
      state.repairStudentsTicketsDataLoading = true;
    });
    builder.addCase(fetchStudentRepairTicketsData.fulfilled, (state, action) => {
      state.repairStudentsTicketsData = action.payload;
      state.repairStudentsTicketsDataLoading = false;
    });
    builder.addCase(fetchStudentRepairTicketsData.rejected, (state) => {
      state.repairStudentsTicketsDataLoading = false;
    });
    builder.addCase(fetchPracticeStudentRepairTicketsData.pending, (state) => {
      state.repairStudentsTicketsDataLoading = true;
    });
    builder.addCase(fetchPracticeStudentRepairTicketsData.fulfilled, (state, action) => {
      state.repairStudentsTicketsData = action.payload;
      state.repairStudentsTicketsDataLoading = false;
    });
    builder.addCase(fetchPracticeStudentRepairTicketsData.rejected, (state) => {
      state.repairStudentsTicketsDataLoading = false;
    });
    builder
      .addMatcher(
        (action) => action.type.startsWith(`${sliceName}/`) && action.type.endsWith('/pending'),
        (state) => {
          state.repairLoading = true; // set loading to true when any async operation starts
        }
      )
      .addMatcher(
        (action) =>
          action.type.startsWith(`${sliceName}/`) &&
          (action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected')),
        (state) => {
          state.repairLoading = false; // set loading to false when any async operation ends
        }
      );
  },
});

export const { resetSelectedItem } = repairSlice.actions;

export default repairSlice.reducer;

export const repairStateItem = createSelector(
  (state: RootState) => state.repair,
  (items: InitialState) => items
);

export const selectRepairBoxesData = createSelector(
  (state: RootState) => state.repair.repairBoxesData,
  (repairBoxesData) => repairBoxesData
);
export const selectRepairBoxesDataLoading = createSelector(
  (state: RootState) => state.repair.repairBoxesDataLoading,
  (loading) => loading
);

export const selectRepairTicketsData = createSelector(
  (state: RootState) => state.repair.repairTicketsData,
  (repairTicketsData) => repairTicketsData
);

export const selectRepairTicketsDataLoading = createSelector(
  (state: RootState) => state.repair.repairTicketsDataLoading,
  (loading) => loading
);

export const selectRepairLoading = createSelector(
  (state: RootState) => state.repair.repairLoading,
  (loading) => loading
);
