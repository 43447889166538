import get from 'lodash/get';
import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from './commonSlice';
import {
  approveRoadmap,
  createRoadMap,
  createVote,
  denyRoadmap,
  fetchRoadMapById,
  getPaginatedList,
  getUserVotes,
  RoadMapCreateInitialType,
  UpdateVote,
  updateVoteApi,
  FetchParamTypesWithPage,
  getFeatureList,
} from '../api/roadMap';
import appLogger from '../infrastructure/config/appLogger';
import { PER_PAGE_RECORD, PER_PAGE_RECORD_10 } from '../constants/data/roadmap.constants';
import { isAPIResponseFailure, PaginatedResponse, RecordType } from '../api/base';
import type { NewVote, RoadMapItem } from '../api/roadMap';
import type { RootState } from '../store';

const sliceName = 'roadmap';

export interface InitialState {
  page?: number;
  perPage: number;
  perPage_10: number;
  totalCount: number;
  totalAllPages: number;
  totalPages: number;
  voteList: any[];
  roadMapList: RoadMapItem[];
  FeatureList: RoadMapItem[] | null;
  selectedItem: null | RoadMapItem;
}

const initialState: InitialState = {
  page: 1,
  perPage: PER_PAGE_RECORD,
  perPage_10: PER_PAGE_RECORD_10,
  totalCount: 0,
  totalAllPages: 0,
  totalPages: 0,
  voteList: [],
  roadMapList: [],
  FeatureList: [],
  selectedItem: null,
};

type FetchWithRoadMapToken = {
  type: string;
  pageNo?: number;
  perPage?: number;
  searchField?: number | string;
  searchValue?: number | string;
  contains?: string;
  token?: any;
};

export const fetchRoadMapItems = createAsyncThunk<
  PaginatedResponse<RoadMapItem> | never[],
  FetchWithRoadMapToken,
  { state: RootState }
>(`${sliceName}/fetch`, async (params, ThunkAPI) => {
  appLogger.log(params);
  const loaderName = `${sliceName}/fetch`;
  ThunkAPI.dispatch(setLoading([loaderName, true]));
  try {
    const todoList = await getPaginatedList(params);
    ThunkAPI.dispatch(setLoading([loaderName, false]));
    if (isAPIResponseFailure(todoList)) {
      return ThunkAPI.rejectWithValue(todoList);
    }
    return todoList.data;
  } catch (e) {
    appLogger.error(e);
  } finally {
    ThunkAPI.dispatch(setLoading([loaderName, false]));
  }
  return [];
});

export const createNewRoadMapItem = createAsyncThunk<RecordType | null, RoadMapCreateInitialType>(
  `${sliceName}/create`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/fetch`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await createRoadMap(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const createNewVote = createAsyncThunk<RecordType | null, NewVote>(
  `${sliceName}/createVote`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/fetch`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await createVote(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const updateVoteCall = createAsyncThunk<RoadMapItem | null, UpdateVote>(
  `${sliceName}/upvote`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/upvote`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await updateVoteApi(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      const itemData = await fetchRoadMapById({ itemId: params.recordId, token: params.token });
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return itemData.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const approveCall = createAsyncThunk<RoadMapItem | null, UpdateVote>(
  `${sliceName}/approve`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/approve`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await approveRoadmap(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      const itemData = await fetchRoadMapById({ itemId: params.recordId, token: params.token });
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return itemData.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

type DeleteWithToken = {
  recordId: number;
  token: string;
};

export const denyCall = createAsyncThunk<RoadMapItem | null, DeleteWithToken>(
  `${sliceName}/deny`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/deny`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await denyRoadmap(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      const itemData = await fetchRoadMapById({ itemId: params.recordId, token: params.token });
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return itemData.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

type FetchWithToken = {
  itemId: number | string;
  token?: string;
};

type FetchWithTokenEmail = {
  userEmail?: string;
  token?: string;
};

export const fetchRoadMapItem = createAsyncThunk<RoadMapItem | null, FetchWithToken>(
  `${sliceName}/fetchById`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/fetchById`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      if (params.itemId !== null) {
        const response = await fetchRoadMapById(params);
        if (isAPIResponseFailure(response)) {
          return thunkAPI.rejectWithValue(response);
        }
        return response.data;
      }
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchFeatureListItem = createAsyncThunk<RoadMapItem[] | null, FetchParamTypesWithPage>(
  `${sliceName}/fetchFeatureList`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/fetchFeatureList`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getFeatureList(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchUserVotes = createAsyncThunk<any, FetchWithTokenEmail>(
  `${sliceName}/fetchByVote`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/fetchByVote`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      if (params.userEmail !== null) {
        const response = await getUserVotes(params);
        if (isAPIResponseFailure(response)) {
          return thunkAPI.rejectWithValue(response);
        }
        return response.data;
      }
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

// @TODO - Add thunk reducer as well
const roadMapSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetSelectedItem: (state) => {
      state.selectedItem = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRoadMapItems.fulfilled, (state, action) => {
      const { payload } = action;
      appLogger.log('payload======147================', payload);
      const totalCount = parseInt(String(get(payload, 'total', '0')), 10);
      state.roadMapList = get(payload, 'rows', []).map((item: any) => ({
        ...item,
        canVote: true,
        hasVoted: false,
      }));
      state.totalCount = totalCount;
      state.totalPages = Math.ceil(totalCount / state.perPage_10);
      state.page = action.meta.arg.pageNo;
      state.perPage_10 = action.meta.arg.perPage || state.perPage_10;
    });
    builder.addCase(fetchRoadMapItem.pending, (state) => {
      state.selectedItem = null;
    });
    builder.addCase(fetchRoadMapItem.fulfilled, (state, action) => {
      state.selectedItem = action.payload;
    });
    builder.addCase(fetchUserVotes.fulfilled, (state, action) => {
      state.voteList = action.payload;
    });
    builder.addCase(fetchFeatureListItem.fulfilled, (state, action) => {
      const { payload } = action;
      state.FeatureList = get(payload, 'rows', []);
      const totalCountList = get(payload, 'total', 0);
      state.totalAllPages = Math.ceil(totalCountList / state.perPage_10);
    });
    builder.addCase(updateVoteCall.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const findIndex = state.roadMapList.findIndex((item) => meta.arg.recordId === item.record_id);
      if (findIndex > -1) {
        state.roadMapList[findIndex] = {
          ...payload,
          canVote: false,
          hasVoted: true,
        } as any;
      }
    });
  },
});

export const { resetSelectedItem } = roadMapSlice.actions;

export default roadMapSlice.reducer;

export const roadMapStateItem = createSelector(
  (state: RootState) => state.roadmap,
  (items: InitialState) => items
);

export const roadMapItemSelectedState = createSelector(
  (state: RootState) => state.roadmap.selectedItem,
  (item: RoadMapItem | null) => item
);
